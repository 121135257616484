import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setSaleOrderStatusesText } from '@redux/actions/cpSaleOrderStatuses';

import SaleOrderStatusesModel from './models/SaleOrderStatusesModel';

const SaleOrderStatuses = ({ fields }) => {
  const dispatch = useDispatch();
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const saleOrderStatusesModel = new SaleOrderStatusesModel();

    setLayoutData(saleOrderStatusesModel.getData(fields));
  }, []);

  useEffect(() => {
    if (layoutData) {
      const soStatuses = {
        draft: layoutData?.['Draft']?.value || '',
        viewed: layoutData?.['Viewed']?.value || '',
        active: layoutData?.['Active']?.value || '',
        completed: layoutData?.['Completed']?.value || '',
        expired: layoutData?.['Expired']?.value || ''
      };

      dispatch(setSaleOrderStatusesText(soStatuses));
    }
  }, [layoutData]);

  return <></>;
};

SaleOrderStatuses.propTypes = {
  fields: object
};

export default SaleOrderStatuses;
