import AdditionalOptionsSettingModel from '@components/BuyingOptions/model/AdditionalOptionsSettingModel';
import AddressSettingModel from '@components/SlicingPage/MyAccount/MyAddress/models/AddressSettingModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const textLabel = [
  'Delivery Fee Label',
  'Free Label',
  'Payment Method Label',
  'Shipping Message',
  'Title',
  'Add Label',
  'Delivery Label',
  'Gifting Option Label',
  'Phone Number',
  'Special Deal Purchase with Purchase Label',
  'Total Saving Label',
  'Discount Label',
  'Order Label',
  'Product List Label',
  'Store Address Label',
  'Download Invoice Label',
  'Order Summery',
  'Quantity Label',
  'Store Availability Message',
  'Track Package Text',
  'Billing Address',
  'Edit Label',
  'Package Title',
  'Remove Label',
  'Store Pick Up Label',
  'Trading In Osim Label',
  'Billing Details Label',
  'Free Gift Label',
  'Paid with Debit Or Credit Card Label',
  'Sales Tax Label',
  'Subtotal Label',
  'You Save Label',
  'Delivery Address Label',
  'Back To Your Detail Label',
  'Billing Add New Address Label',
  'Billing Address Label',
  'Billing Same as Delivery Address Label',
  'Billing See More Address Label',
  'Billing Title',
  'Continue Payment Label',
  'Default Label',
  'Delivery Add New Address Label',
  'Delivery Address Label',
  'Delivery Package Title',
  'Delivery Product List Label',
  'Delivery See More Address Label',
  'Free Gift Label',
  'Gifting Option Label',
  'No Address Message',
  'PopUp Add New Address Button Text',
  'PopUp Add New Address Label',
  'PopUp Address Line 1 Label',
  'PopUp Address Line 2 Label',
  'PopUp Address Name Label',
  'PopUp Area Selection Label',
  'PopUp City Selection Label',
  'PopUp Default Label',
  'PopUp Province Selection Label',
  'PopUp Select Address Label',
  'PopUp Select Label',
  'PopUp Use this for Billing Address Label',
  'PopUp Zip Portal Code Label',
  'Quantity Label',
  'SF Pickup Address Label',
  'SF Pickup Package Title',
  'SF Pickup Product List Label',
  'SF Pickup See More Address Label',
  'Special Deal Purchase with Purchase Label',
  'Delivery You Saved Label',
  'Delivery Quantity Label',
  'Delivery Free Gift Label',
  'Delivery Free Label',
  'Back To Cart Label',
  'Delivery Subtotal Label',
  'Description For Multiple Packages',
  'Description For Single Package',
  'Phone Number',
  'Please Select Address Message',
  'PopUp Accept Label',
  'PopUp Confirm Description',
  'PopUp Confirm Select Address Label',
  'PopUp Cancel Label',
  'Staff ID Label',
  'Store ID Label',
  'Paid With Label',
  'Pay In Full Label',
  'Pay In Installment Label',
  'Select Area And District Label',
  'Special Request Label',
  'Pick Up Fee Label',
  'Discount Description',
  'Coupon Code Label',
  'Billing Discount Label',
  'Billing Total Label',
  'Billing Delivery Fee Label',
  'Billing Sale Tax Label',
  'Billing Total Saving Label',
  'Applied Offers Title',
  'Free Gift Hint',
  'Gift Card Label',
  'Gift Wrap Label',
  'Refund Order Label',
  'Gifting Option Free Label',
  'Delivery SF Express Package Label',
  'Deposit Payment Label',
  'Deposit Paid Label',
  'Extended Warranty Label',
  'Deposit Free Gift Label',
  'Balance Payment Label',
  'Balance Label',
  'From To Date Label',
  'Discount Label',
  'Total Balance Payment Label',
  'Total Deposit Label',
  'Deposit Tag Label',
  'PayBalance Button Text',
  'Sub Total',
  'Balance Free Gift Note Message',
  'Add On Deal Label',
  'Pre-order Offer Tag Label',
  'Completed Label',
  'Deposit Free Gift Note Messages'
];

const linkLable = [
  'Track Package Link',
  'Download Invoice Link',
  'PayBalance Button Link'
];

export default class OrderDetailModel {
  constructor() {
    textLabel.forEach((itemText) => {
      this[itemText] = new SitecoreTextModel();
    });

    linkLable.forEach((itemText) => {
      this[itemText] = new SitecoreLinkModel();
    });

    this['Show Sales Tax'] = false;

    this['Area Shipping Fee Setting'] = new AddressSettingModel();

    this['Learn More Label'] = new SitecoreTextModel();

    this['Valid Until Label'] = new SitecoreTextModel();

    this['Coupon Detail Title'] = new SitecoreTextModel();

    this['To Label'] = new SitecoreTextModel();

    this['Applied Offers Description'] = new SitecoreTextModel();

    this['Additional Options Setting'] = new AdditionalOptionsSettingModel();
  }

  getData(dataSource) {
    textLabel.forEach((itemText) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, itemText)) {
        this[itemText].getData(dataSource[itemText]);
      }
    });

    linkLable.forEach((itemText) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, itemText)) {
        this[itemText].getData(dataSource[itemText]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Area Shipping Fee Setting')) {
      this['Area Shipping Fee Setting'].getData(dataSource['Area Shipping Fee Setting']?.fields || {});
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Cart Configuration') && dataSource['Cart Configuration']?.fields) {
      this['Show Sales Tax'] = dataSource['Cart Configuration'].fields['Show Sales Tax']?.value;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Label')) {
      this['Learn More Label'].getData(dataSource['Learn More Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Valid Until Label')) {
      this['Valid Until Label'].getData(dataSource['Valid Until Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Coupon Detail Title')) {
      this['Coupon Detail Title'].getData(dataSource['Coupon Detail Title'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'To Label')) {
      this['To Label'].getData(dataSource['To Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Applied Offers Description')) {
      this['Applied Offers Description'].getData(dataSource['Applied Offers Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Additional Options Setting')) {
      this['Additional Options Setting'].getData(dataSource['Additional Options Setting']);
    }

    return this;
  }
}
