import { object } from 'prop-types';
import React, { useCallback } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

const LocationSwiper = ({ layoutData }) => {
  let params = {
    loop: true,
    lazy: true,
    slidesPerView: 1.24,
    spaceBetween: 16,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      992: {
        slidesPerView: layoutData?.Location.length < 3 ? layoutData?.Location.length : 3,
        spaceBetween: 40
      }
    }
  };

  const openTimeText = useCallback(
    (timeSlots) => {
      const openFromText = layoutData?.['Open From Text']?.value;
      const openToText = layoutData?.['Open To Text']?.value;
      const sortTimeSlot = timeSlots.sort((a, b) => a.Time.localeCompare(b.Time));
      const startTime = sortTimeSlot?.[0]?.Time || '';
      const endTime = sortTimeSlot?.[sortTimeSlot.length - 1]?.Time || '';

      return `${openFromText} ${startTime} ${openToText} ${endTime}`;
    },
    [layoutData]
  );

  return (
    <div className='location__swiper'>
      <div className='location__swiper-container'>
        <SwipeComponent param={params}>
          {layoutData?.Location &&
            layoutData?.Location.map((item, index) => (
              <div key={index} className='swiper-location__container'>
                <Text tag='h5' field={{ value: item['Title'] }} />
                <Text tag='p' field={{ value: item['Date Slot']?.[0]?.['Event Date Format 1'] }} />
                <p>{openTimeText(item['Date Slot']?.[0]?.['Time Slots'])}</p>
                <RichText tag='p' field={{ value: item['Address Details'] }} />
              </div>
            ))}
        </SwipeComponent>
      </div>
    </div>
  );
};

LocationSwiper.propTypes = {
  layoutData: object
};

export default LocationSwiper;
