import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const sitecoreTextModelArr = [
  'Action Text',
  'CTA button Text',
  'No Result Description',
  'No Result Text',
  'Result Text',
  'Return Button Text',
  'Title'
];

const sitecoreLinkModelArr = ['CTA Link', 'Customer Profile Link', 'Return Button Link'];

export default class CPSearchResultModel {
  constructor() {
    sitecoreTextModelArr.map((item) => {
      this[item] = new SitecoreTextModel();
    });

    sitecoreLinkModelArr.map((item) => {
      this[item] = new SitecoreLinkModel();
    });
  }
  getData(dataSource) {
    sitecoreTextModelArr.map((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    sitecoreLinkModelArr.map((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
