import './CPSearchResult.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CPPageHeader from '@components/ClientPortal/CPPageHeader';
import CPPaging from '@components/ClientPortal/CPPaging';
import {
  CLIENT_TELLING_SEARCH_CONTACT_INIT_PAGE,
  CLIENT_TELLING_SEARCH_CONTACT_PAGESZ
} from '@components/ClientPortal/CustomerProfileListing/CPSearchResult/configs';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import { searchCPCustomer } from '@redux/actions/clientTellingPortalCustomer';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import CPSearchResultShimmer from './CPSearchResultShimmer';
import CPSearchResultModel from './model/CPSearchResultModel';

const CPSearchResult = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);
  const dispatch = useDispatch();
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const [description, setDescription] = useState(null);

  const [reset, setReset] = useState({
    pagingReset: false
  });

  const [searchParams, setSearchParams] = useState({
    searchTerm: '',
    page: CLIENT_TELLING_SEARCH_CONTACT_INIT_PAGE,
    pageSz: CLIENT_TELLING_SEARCH_CONTACT_PAGESZ,
    isDispatch: false
  });

  useEffect(() => {
    const model = new CPSearchResultModel();
    const URLSearchTerm = Global.decodeURLQueryParams(Global.getQueryParamsNoLower(window.location.href, 'term')) || '';

    setReset({ pagingReset: !reset.pagingReset });

    setLayoutData(model.getData(deepCopy(fields || {})));

    setSearchParams({
      ...searchParams,
      searchTerm: URLSearchTerm,
      isDispatch: !searchParams.isDispatch
    });
  }, []);

  useDidUpdateEffect(() => {
    dispatch(
      searchCPCustomer({
        term: searchParams.searchTerm,
        page: searchParams.page,
        pageSz: searchParams.pageSz
      })
    );
  }, [searchParams.isDispatch]);

  useDidUpdateEffect(() => {
    let tmpDesc = { ...layoutData['Result Text'] };

    tmpDesc.value = `${clientTellingPortalCustomer.searchResults.length} ${tmpDesc.value} for "${searchParams.searchTerm}"`;

    setDescription(tmpDesc);
  }, [clientTellingPortalCustomer.searchResults]);

  const onChangePageHandler = (pageNum) => {
    setSearchParams({
      ...searchParams,
      page: pageNum,
      isDispatch: !searchParams.isDispatch
    });
  };

  return layoutData ? (
    <div className='customer-listing'>
      {clientTellingPortalCustomer.isLoadingSearchResult ? (
        <>
          <CPPageHeader />
          <div className='container tablet-container'>
            <div className='customer-listing__table'>
              <table className='cp-table'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Phone Number</th>
                    <th>Email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <LoadingShimmer itemNumb={CLIENT_TELLING_SEARCH_CONTACT_PAGESZ}>
                    <CPSearchResultShimmer />
                  </LoadingShimmer>
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <>
          <CPPageHeader title={layoutData['Title']} description={description} />
          {clientTellingPortalCustomer.searchResults.length ? (
            <div className='container tablet-container'>
              <div className='customer-listing__table'>
                <table className='cp-table'>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Phone Number</th>
                      <th>Email</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clientTellingPortalCustomer.searchResults.map((res) => {
                      return (
                        <tr key={res.contactId}>
                          <td>{`${res?.json_facet?.Personal?.FirstName || ''} ${res?.json_facet?.Personal?.LastName || ''}`}</td>
                          <td>{res?.json_facet?.PhoneNumbers?.PreferredPhoneNumber?.Number || ''}</td>
                          <td>{res?.json_facet?.Email?.PreferredEmail?.SmtpAddress || ''}</td>
                          <td>
                            <a
                              href={`${layoutData['Customer Profile Link'].value.href}?contactId=${res?.contactId}`}
                              className='link-nomal text-blue'
                            >
                              <Text field={layoutData['Action Text']} />
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className='search-no-result'>
              <div className='container tablet-container'>
                <div className='search-no-result__content'>
                  <div className='search-no-result__content__image'>
                    <img src={require('@assets/images/CP-images/icon-search-big.png')} alt='icon search' />
                  </div>
                  <Text field={layoutData['No Result Text']} className='search-no-result__content__label text-center' tag='h5' />
                  <Text field={layoutData['No Result Description']} className='search-no-result__content__desc text-center' tag='p' />
                  <div className='search-no-result__content__btn'>
                    <Link
                      field={layoutData['CTA Link']}
                      className='search-no-result__content__btn__item btn btn-os-orange btn-os-orange--text-primary'
                    >
                      <Text field={layoutData['CTA button Text']} />
                    </Link>
                    <Link className='search-no-result__content__btn__item os-btn-link' field={layoutData['Return Button Link']}>
                      <Text field={layoutData['Return Button Text']} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <CPPaging
        isReset={false}
        itemPerPage={CLIENT_TELLING_SEARCH_CONTACT_PAGESZ}
        totalItem={clientTellingPortalCustomer.searchResultsLength}
        onChangePageHandler={onChangePageHandler}
      />
    </div>
  ) : (
    <></>
  );
};

CPSearchResult.propTypes = {
  fields: object
};

export default CPSearchResult;
