import './ProductDetailOverview.scss';

import Proptypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import ResponsiveImage from '@components/ResponsiveImage';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import InfoStockComponent from '@components/Share/InfoStockComponent';
import Loading from '@components/Share/Loading';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import Message from '@components/Share/Message';
import OmnichatTracking from '@components/Share/OmnichatTracking';
import SwipeComponent from '@components/Share/SwipeComponent';
import ToggleWisList from '@components/Share/ToggleWisList';
import SitecoreTextModel from '@models/SitecoreTextModel';
import {
  changeRecentlyProduct,
  getProductDetailSingle,
  setRecentlyProduct,
  setVariantProductDetailHeader,
  setVariantProductDetailOverviewActive,
  setVariantSelected
} from '@redux/actions/product';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { PRODUCT_DETAIL_DISPLAY_IMAGES } from '@utils/constant';
import { STOCK_QUANTITY } from '@utils/constant';
import { HK_SITE_NAME } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import CustomPaginationSwiper from './CustomPaginationSwiper';
import ProductDetailOverviewModel from './models/ProductDetailOverviewModel';
import ProductVariantImage from './ProductVariantImage';
import ProductVariantInfo from './ProductVariantInfo';
import ProductVariantInfoShimmer from './ProductVariantInfo/ProductVariantInfoShimmer';
import SwiperComponentZoom from './SwiperComponentZoom';

const ProductDetailOverview = (props) => {
  const componentId = (props.params && props.params['Component Id']) || '';
  const dispatch = useDispatch();
  const { fields, productDetailOverview, variantActiveDefault, isGetDataSuccess, variantSelected } = props;
  const [dataSources, setDataSources] = useState();
  const [variantActive, setVariant] = useState(null);
  const [slideTo, setSlideTo] = useState(0);
  const [toggleGallery, setToggleGallery] = useState(false);
  const productIdCurrent = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Commerce Product Source']?.fields?.ProductId?.value;
  const [productId] = useState(productIdCurrent);
  const productPageId = SitecoreContextFactoryService.getValueContextRouteItem('itemId');
  const imageSwiperRef = useRef(null);
  const [LoadedGTM, setLoadedGTM] = useState(false);
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const isTurnOnOmnichat = SitecoreContextFactoryService.getValueContextItem('HKOmnichat');
  const isGA4 = Global.getIsGA4();
  const contextRouteItem = SitecoreContextFactoryService.getValueContextRouteItem('fields');
  const [isShowedMainImage,setIsShowedMainImage]=useState(false);

  let params = {
    lazy: true,
    slidesPerView: PRODUCT_DETAIL_DISPLAY_IMAGES,
    centeredSlides: false,
    spaceBetween: 0,
    watchOverflow: true,
    pagination: {
      el: '.swiper-pagination__product-overview',
      type: 'custom',
      clickable: true
    },
    on: {
      slideChangeTransitionEnd: () => {
        // INFO: get to know the main image has been showed
        setIsShowedMainImage(true);

        setSlideTo(imageSwiperRef.current.swiper.realIndex);
      }
    },

    // eslint-disable-next-line react/display-name
    renderPagination: (swiper) => {
      const onSlideToHandler = (slideKey) => {
        setSlideTo(slideKey);

        // INFO: get to know the main image has been showed
        setIsShowedMainImage(true);

        imageSwiperRef.current.swiper.slideTo(slideKey);
      };

      return (
        <CustomPaginationSwiper
          activeIndex={slideTo}
          setActiveKeyEvt={onSlideToHandler}
          prevSlideEvt={onSlideToHandler}
          nextSlideEvt={onSlideToHandler}
          swiper={swiper}
        />
      );
    }
  };

  let variantParams = {
    slidesPerView: 'auto',
    centeredSlides: false,
    centerInsufficientSlides: true,
    spaceBetween: 8,
    watchOverflow: true,
    breakpoints: {
      768: {
        spaceBetween: 12
      },
      1200: {
        spaceBetween: 0
      }
    }
  };

  useEffect(() => {
    setDataSources(new ProductDetailOverviewModel().getData(deepCopy(fields || {})));

    dispatch(getProductDetailSingle());
  }, []);

  useDidUpdateEffect(() => {
    dispatch(changeRecentlyProduct({ ProductId: productId }));

    dispatch(
      setRecentlyProduct({
        ...variantActiveDefault,
        ProductId: productId,
        ProductPageId: productPageId
      })
    );
  }, [isGetDataSuccess]);

  useDidUpdateEffect(() => {
    setVariant({
      ...variantActiveDefault,
      ProductId: productId
    });

    dispatch(setVariantSelected({
      ...variantActiveDefault,
      ProductId: productId
    }));
  }, [variantActiveDefault]);

  const onClickVariantHandler = (variantItem) => {
    // INFO: get to know the main image has been showed
    setIsShowedMainImage(true);

    dispatch(
      setVariantProductDetailHeader({
        ...variantItem,
        ProductId: productId
      })
    );

    dispatch(
      setVariantProductDetailOverviewActive({
        ...variantItem,
        ProductId: productId
      })
    );
    if (variantItem.VariantId !== variantActive?.VariantId) {
      setSlideTo(0);
    }

    setVariant(variantItem);

    dispatch(setVariantSelected({
      ...variantItem
    }));

    // Push data to GA4
    if (isGA4) {
      let paramGA4 = {
        ecommerce: Global.handleSwitchVariant(variantSelected, variantItem, '')
      };

      new GoogleAnalytic().gAnalytic4('event', 'switch_variant', paramGA4);
    }
  };

  const onCloseZoomImagesHandler = (slideIndex) => {
    setToggleGallery(false);

    setSlideTo(slideIndex);

    imageSwiperRef.current.swiper.slideTo(slideIndex);
  };

  useDidUpdateEffect(() => {
    // INF: Google analytics
    if (document.referrer.includes('Search?Term')) {
      let params = {
        items: [
          {
            id: contextRouteItem?.['Commerce Product Source']?.fields?.ProductId?.value,
            name: SitecoreContextFactoryService.getValueContextRouteItem('name'),
            list_name: 'Search Results',
            brand: 'Osim',
            category: variantActive?.CategoryName,
            variant: variantActive?.Color,
            list_position: 1,
            quantity: variantActive?.Stock?.StockQuantity,
            price: variantActive?.Price?.SellingPrice
          }
        ]
      };

      new GoogleAnalytic().ggGateWay('event', 'select_content', params);
    } else {
      //Old Logic GA
      // let params = {
      //   items: [
      //     {
      //       id: contextRouteItem?.['Commerce Product Source']?.fields?.ProductId?.value,
      //       name: SitecoreContextFactoryService.getValueContextRouteItem('name'),
      //       list_name: 'Search Results',
      //       brand: 'Osim',
      //       category: variantActive?.CategoryName,
      //       variant: variantActive?.Color,
      //       list_position: 1,
      //       quantity: variantActive?.Stock?.StockQuantity,
      //       price: variantActive?.Price?.SellingPrice
      //     }]
      // };

      // new GoogleAnalytic().ggGateWay('event', 'view_item', params);

      //New Logic ON/OFF GA, GA4
      if (isGA4) {
        const queryParams = new URLSearchParams(window.location.search);
        const pdpPromoCode = queryParams.get('cCode');
        const idxProdGA4 = Global.getIndexGA4Param();
        let dataConvert = [];

        dataConvert.push(variantActive);

        let paramsViewItem = {
          ecommerce: {
            currency: variantActive?.Price?.CurrencyCode,
            value: variantActive?.Price?.SellingPrice,
            items: Global.handleGA4ViewAndSelectItems(idxProdGA4, dataConvert, '', pdpPromoCode, productDetailOverview?.Brand)
          }
        };
  
        new GoogleAnalytic().ggGateWay('event', 'view_item', paramsViewItem);
      } else {
        let params = {
          items: [
            {
              id: contextRouteItem?.['Commerce Product Source']?.fields?.ProductId?.value,
              name: SitecoreContextFactoryService.getValueContextRouteItem('name'),
              list_name: 'Search Results',
              brand: 'Osim',
              category: variantActive?.CategoryName,
              variant: variantActive?.Color,
              list_position: 1,
              quantity: variantActive?.Stock?.StockQuantity,
              price: variantActive?.Price?.SellingPrice
            }]
        };
  
        new GoogleAnalytic().ggGateWay('event', 'view_item', params);
      }
    }

    setLoadedGTM(!LoadedGTM);
  }, [variantActive]);

  useDidUpdateEffect(() => {
    // let obj = {
    //   ecommerce: {
    //     detail: {
    //       products: [
    //         {
    //           name: variantActive?.ProductName,
    //           id: variantActive?.ProductId,
    //           price: variantActive?.Price?.SellingPrice.toString(),
    //           category: variantActive?.CategoryName
    //         }
    //       ]
    //     }
    //   }
    // };

    // new GoogleAnalytic().ggGateWay(obj);

    window.dataLayer = window.dataLayer || [];

    // window.dataLayer.push({
    //   ecommerce: {
    //     detail: {
    //       products: [
    //         {
    //           name: variantActive?.ProductName,
    //           id: variantActive?.ProductId,
    //           price: variantActive?.Price?.SellingPrice.toString(),
    //           category: variantActive?.CategoryName
    //         }
    //       ]
    //     }
    //   }
    // });

    let params = {
      ecommerce: {
        detail: {
          products: [
            {
              name: variantActive?.ProductName,
              id: variantActive?.ProductId,
              price: variantActive?.Price?.SellingPrice.toString(),
              category: variantActive?.CategoryName
            }
          ]
        }
      }
    };

    window.dataLayer.push(params);
    // new GoogleAnalytic().ggGateWay(params);
  }, [LoadedGTM]);

  useDidUpdateEffect(() => {
    // INFO: Omnichat Tracing for HK site
    if (currentSiteName === HK_SITE_NAME && isTurnOnOmnichat) {
      const productDetail = {
        id: contextRouteItem?.['Commerce Product Source']?.fields?.ProductId?.value || '',
        name: SitecoreContextFactoryService.getValueContextRouteItem('name') || '',
        brand: 'Osim',
        category: variantActive?.CategoryName || '',
        variant: variantActive?.Color || '',
        price: variantActive?.Price?.SellingPrice || ''
      };

      new OmnichatTracking().tracking('event', 'view_product', productDetail);
    }
  }, [variantActive]);

  const variantImageUrl = useCallback((source) => {
    if (!isShowedMainImage) {
      return productDetailOverview?.MainImage || source;
    }

    return source;
  }, [isShowedMainImage, productDetailOverview]);

  return (
    <div className='product-variant' id={componentId}>
      <input type='hidden' value={productPageId} id='product-detail-page' />
      <div
        className={`${
          props.params?.['Text Color'] === 'White' ? 'product-variant__wrap' : 'product-variant__wrap product-variant__wrap--button-dark'
        }`}
      >
        {variantActive ? (
          <Fragment>
            {(variantActive.MobileBannerImage || variantActive.BannerImage) ? (
              <ResponsiveImage
                cssClassPicture='product-variant__wrap__background'
                imageMobile={variantActive.MobileBannerImage}
                srcSet={[ { mw: 768, image: variantActive.BannerImage} ]}
                attributes={{
                  className: 'product-variant__color__item__image',
                  alt: variantActive.BannerImageAlt
                }}
              />
            ) : (
              <span className='product-variant__wrap__background'></span>
            )}
            <div className='product-variant__content'>
              <div className='container no-padding-mobile'>
                <div
                  className={`product-variant__wrap__item ${
                    props.params['Indicator Color'] === 'White' ? 'product-variant__wrap__item--indicator-white' : ''
                  }`}
                >
                  <div className='product-variant__icon__zoom' onClick={() => setToggleGallery(!toggleGallery)}></div>
                  <SwipeComponent key={variantActive?.VariantId} param={params} setRef={imageSwiperRef}>
                    {variantActive?.Images?.map((itemImage, itemImageIdx) => (
                      <div key={itemImageIdx} className='product-variant__wrap__item__img'>
                        {itemImage ? (
                          <ProductVariantImage inputData={variantImageUrl(itemImage)} />
                        ) : (
                          <></>
                        )}
                      </div>
                    ))}
                  </SwipeComponent>
                </div>
                <div
                  className={`product-variant__info product-variant__info--desktop ${props.params?.['Text Color'] === 'White' ? 'text-light' : ''}`}
                >
                  {dataSources ? (
                    <ProductVariantInfo dataContent={variantActive} dataSources={dataSources} productDetailOverview={productDetailOverview} />
                  ) : (
                    ''
                  )}
                  {contextRouteItem?.['QR Code Image']?.value?.src ? (
                    <Image field={contextRouteItem?.['QR Code Image']} />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <LoadingShimmer itemNumb={1}>
            <div className='product-variant__wrap__background skeleton__item'>
              <Loading></Loading>
            </div>
          </LoadingShimmer>
        )}
      </div>
      <div className='product-variant-menu'>
        <div className='container'>
          <div className='product-variant-menu__wrap'>
            <Text
              className='product-variant-menu__label animated-slow fadeIn delay-150'
              field={new SitecoreTextModel().getData({ value: productDetailOverview?.ExploreLabel || '' })}
              tag='p'
            />
            <div className='product-variant-menu__list-item'>
              {productDetailOverview?.VariantDetail?.length ? (
                <SwipeComponent param={variantParams}>
                  {productDetailOverview.VariantDetail.map((variantItem) => (
                    <div
                      key={variantItem.VariantId}
                      className={`product-variant-item animated-slow fadeIn delay-150 ${
                        variantItem.VariantId === variantActive?.VariantId ? 'is-active' : ''
                      }`}
                      onClick={() => onClickVariantHandler(variantItem)}
                    >
                      <div className='product-variant-item__img'>
                        {variantItem.Images[0] ? (
                          <img src={variantItem.Images[0]} alt={variantItem.Images[0] ? Global.getQueryParams(variantItem.Images[0], 'alt') : 'no image'} />
                        ):(
                          <></>
                        )}
                      </div>
                      <div className='product-variant-item__name'>
                        {variantItem.VariantPropertyValue}
                        <InfoStockComponent
                          stock={{
                            ...variantItem.Stock,
                            LimitStockQuantity: variantItem.Stock.LimitStockQuantity || productDetailOverview.LimitStockQuantity
                          }}
                        >
                          {variantItem.Stock.LimitStockQuantity
                            ? variantItem.LastLabel
                            : productDetailOverview.LimitStockQuantity
                              ? productDetailOverview.LastLabel?.replace(STOCK_QUANTITY, variantItem.Stock.PurchasableLimit || 0)
                              : ''}
                        </InfoStockComponent>
                      </div>
                    </div>
                  ))}
                </SwipeComponent>
              ) : (
                <ul className='product-variant-menu__list-item--shimmer'>
                  <li className='product-variant-item skeleton__item'>
                    <div className='product-variant-item__img'>
                      <span className='image-line'></span>
                    </div>
                    <span className='product-variant-item__name'>
                      <span className='text-line'></span>
                    </span>
                  </li>
                  <li className='product-variant-item skeleton__item'>
                    <div className='product-variant-item__img'>
                      <span className='image-line'></span>
                    </div>
                    <span className='product-variant-item__name'>
                      <span className='text-line'></span>
                    </span>
                  </li>
                  <li className='product-variant-item skeleton__item'>
                    <div className='product-variant-item__img'>
                      <span className='image-line'></span>
                    </div>
                    <span className='product-variant-item__name'>
                      <span className='text-line'></span>
                    </span>
                  </li>
                </ul>
              )}
            </div>
            {variantActive ? (
              <div className='product-variant__status'>
                {contextRouteItem?.['Hide Icon And Fulfillment Details']?.value === false ? (
                  <>
                    <span className='product-variant__status__icon product-info-icon product-info-icon--delivery'></span>
                    {variantActive.IsInStockProduct ? (
                      <span>
                        <Message messageCode='LBL-InStock' labelShipping={variantActive['InStockDeliveryLabel']} />
                      </span>
                    ) : (
                      ''
                    )}
                    {variantActive.IsOutStockProduct ? (
                      <span>
                        <Message messageCode='LBL-OutOfStock' labelShipping={variantActive['OutStockDeliveryLabel']} />
                      </span>
                    ) : (
                      ''
                    )}
                    {variantActive.IsPreOrderProduct ? (
                      <span>
                        <Message messageCode='LBL-PreOrder' labelShipping={variantActive['PreOrderDeliveryLabel']} />
                      </span>
                    ) : (
                      ''
                    )}
                    {variantActive.IsBackOrderProduct ? (
                      <span>
                        <Message messageCode='LBL-BackOrder' labelShipping={variantActive['BackOrderDeliveryLabel']} />
                      </span>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {dataSources && variantActive ? (
        <div className='container product-variant__info--mobile animated-slow fadeIn delay-100'>
          <ProductVariantInfo dataContent={variantActive} dataSources={dataSources} productDetailOverview={productDetailOverview}>
            {/* <Text field={dataSources['Learn More Text']} tag='span' /> */}
          </ProductVariantInfo>
        </div>
      ) : (
        <LoadingShimmer itemNumb={1}>
          <div className='container product-variant__info--mobile'>
            <ProductVariantInfoShimmer></ProductVariantInfoShimmer>
          </div>
        </LoadingShimmer>
      )}
      {variantActive && toggleGallery ? (
        <SwiperComponentZoom
          setCloseZoomImages={onCloseZoomImagesHandler}
          toggleGallery={toggleGallery}
          variantActive={variantActive}
          activeSlideKey={slideTo}
        />
      ) : (
        ''
      )}
    </div>
  );
};

ProductDetailOverview.propTypes = {
  fields: Proptypes.object,
  productDetailOverview: Proptypes.object,
  isLogin: Proptypes.bool,
  variantActiveDefault: Proptypes.object,
  isGetDataSuccess: Proptypes.bool,
  rendering: Proptypes.any,
  params: Proptypes.any,
  variantSelected: Proptypes.any,
  variantPropertyName: Proptypes.any
};

const mapStateToProps = (state, action) => {  
  return ({
    productDetailOverview: state.productReducer.productDetailOverviewReducer.productDetailOverview,
    variantActiveDefault: state.productReducer.productDetailOverviewReducer.VariantActiveDefault,
    isGetDataSuccess: state.productReducer.productDetailOverviewReducer.isGetDataSuccess,
    currentCartLines: state.productReducer.cartDetailReducer?.currentCart?.Lines,
    variantSelected: state.productReducer.productDetailOverviewReducer.variantSelected,
    isLogin: state.singleSignOnReducer.userInfoReducer?.isLogin
  });
};

export default connect(mapStateToProps)(ProductDetailOverview);
