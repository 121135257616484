import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CART_PAGE_NAME } from '@components/FreeGiftPopup/FreeGiftItem';
import Message from '@components/Share/Message';
import ModalComponent from '@components/Share/ModalComponent';
import { showSelectingFreeGiftPopup } from '@redux/actions/controlSelectingFreeGiftPopup';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, Link,Text } from '@sitecore-jss/sitecore-jss-react';
import { PromotionType } from '@utils/constant';
import {BALANCE_FREEGIFT_TYPE,DEPOSIT_FREEGIFT_TYPE } from '@utils/constant';
import global from '@utils/global';

import orderDetail from '../../../../ClientPortal/SaveSaleOrderSuccess/SaveSOSuccessBody/OrderDetail';
import { useConvertBalanceDate } from '../../../../Product/VariantDetail/ProductDetailOverview/ProductVariantInfo/utils';
import { orderStatusKeys } from './configs';
import ProductWithDepositFreeGift from './ProductWithDepositFreeGift';

// import BalanceDetailModals from '../../../../SlicingPage/BanlancePayment/BalanceDetails/BalanceDetailModals';
const NAME_REPLACE = '{0}';
const DEFAULT_PRICE = 'S$0.00';

const ProductListItem = ({ data, page, layoutData }) => {
  const dispatch = useDispatch();
  const outOfStockModel = data.LineStatus.find(lineItem => lineItem.Code === 'OutOfStock');
  const showShippingAreaError = useSelector((state) => state.productReducer.cartDetailReducer.checkShippingArea);
  const currentCart = useSelector((state)=> state?.myOrderReducer?.orderByIdReducer?.currentCart || state?.productReducer?.cartDetailReducer?.currentCart);
  const currentCartBalance = useSelector((state)=> state?.myOrderReducer?.balanceByIdReducer?.currentCart);
  const objMessages = useSelector((state) => state.getMessageReducer?.objMessages || null);
  const dataDeposit = useSelector((state)=> state?.myOrderReducer?.orderByIdReducer?.currentCartCustom?.DepositListProduct?.[0]?.Deposit);
  const [isShowModal, setShowModal] = useState(null);
  const [isButtonPayBalance, setButtonPayBalance] = useState(false);
  const convertBalanceDateService = useConvertBalanceDate();

  const isDeposit = useMemo(() => {
    return data?.Deposit !== null;
  }, [data]);
  const pageName = SitecoreContextFactoryService.getValueContextRouteItem('name')?.toLowerCase();
  // const [isShow, setShow] = useState(false);

  const nameDepositFreeGift = currentCart?.DepositFreeGifts?.find((item) => item?.Type === 'DepositFreeGift');
  const nameBalanaceFreeGift = currentCart?.DepositFreeGifts?.find((item) => item?.Type === 'BalanaceFreeGift');

  let params = {
    centered: true
  };

  const onGetIconHandler = (relationshipId, layoutData) => {
    let option = layoutData?.['Additional Options Setting']?.fields?.['Allowed Options'].find((option) => {
      return option?.fields?.Relationship?.fields?.['Field Name'].value === relationshipId;
    });

    return option?.fields?.Icon;
  };

  const getInfoGift = (giftId, giftingOptionLines) => {
    if (giftId && giftingOptionLines) {
      return giftingOptionLines.find((gift) => giftId === gift.ProductId);
    }

    return;
  };

  // const onShowHandler = () => {
  //   setShow(true);
  // };

  // const onCloseHandler = (event) => {
  //   event.preventDefault();

  //   setShow(false);
  // };
  const handleOpenSelectingFreeGiftPopup = () => dispatch(showSelectingFreeGiftPopup());

  useEffect(()=> {
    if(dataDeposit) {
      const currentDate = new Date ();
      const unixTimestamp = currentDate.getTime();
      const unixTimestampStart = parseInt((dataDeposit?.BalanceStartDate).match(/\d+/)[0]);
      const unixTimestampEnd = parseInt((dataDeposit?.BalanceEndDate).match(/\d+/)[0]);

      if(unixTimestamp >= unixTimestampStart && unixTimestamp <= unixTimestampEnd) {
        setButtonPayBalance(true);
      } else {
        setButtonPayBalance(false);
      }
    }
  },[dataDeposit]);

  const completedText = useMemo(() => {
    return layoutData?.['Completed Label']?.value;
  }, [layoutData]);

  const isDepositWaitingForPaymentStatus = useMemo(() => {
    return currentCart?.OrderStatus === orderStatusKeys.waitingForPayment;
  }, [currentCart]);

  const depositOrderStatusText = useMemo(() => {
    let statusText = completedText;
    if (isDepositWaitingForPaymentStatus) {
      const fromToDateText = layoutData?.['From To Date Label']?.value;
      const depositData = data?.Deposit;
      const depositStartDate = convertBalanceDateService(depositData?.DepositStartDate);
      const depositEndDate = convertBalanceDateService(depositData?.DepositEndDate);

      statusText = fromToDateText?.replace('{StartDate}', depositStartDate)?.replace('{EndDate}', depositEndDate);
    }

    return statusText;
  }, [isDepositWaitingForPaymentStatus, data, completedText]);

  const isBalancePaymentComplete = useMemo(() => {
    const orderStatus = currentCart?.OrderStatus;
    const balancePaymentStatusTypes = [orderStatusKeys.waitingForPayment, orderStatusKeys.pendingBalancePayment, orderStatusKeys.balancePaymentExpired];

    return orderStatus ? !balancePaymentStatusTypes.includes(orderStatus) : false;
  }, [currentCart]);

  const payBalanceOrderStatusText = useMemo(() => {
    let statusText = completedText;
    if (!isBalancePaymentComplete) {
      const fromToDateText = layoutData?.['From To Date Label']?.value;
      const depositData = data?.Deposit;
      const depositStartDate = convertBalanceDateService(depositData?.BalanceStartDate);
      const depositEndDate = convertBalanceDateService(depositData?.BalanceEndDate);

      statusText = fromToDateText?.replace('{StartDate}', depositStartDate)?.replace('{EndDate}', depositEndDate);
    }

    return statusText;
  }, [isBalancePaymentComplete, data, completedText]);

  const freeGiftNoteText = useMemo(() => {
    return isBalancePaymentComplete
      ? layoutData?.['Balance Free Gift Note Message']
      : layoutData?.['Deposit Free Gift Note Messages'];
  }, [layoutData, isBalancePaymentComplete]);

  return (
    data && (
      <div className='product-list__item' id={data.ExternalCartLineId}>
        <div className='delivery-row'>
          <div className='delivery-col-left'>
            {data.SubLines && data.SubLines.length > 0 ? (
              <div className='product-list__image'>
                <div className='row cart-item__image-row'>
                  {data.SubLines.map((item) => {
                    return (
                      <div className='col-6 cart-item__image' key={item.ExternalCartLineId}>
                        <img src={item.Image} />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className='product-list__image'>
                <div className='row cart-item__image-row'>
                  <div className='col-12 cart-item__image'>
                    <img src={data.Image} alt='' />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='delivery-col-right'>
            <div className='delivery-col-right__message'>
              {outOfStockModel ? <p className='error-message'>{outOfStockModel?.Errors?.[0]}</p> : ''}
              {data.Type === PromotionType.PwP && !data?.isOnlyMasterId ? (
                <p className='special-deal-message'>
                  <Text field={layoutData['Special Deal Purchase with Purchase Label']} />
                </p>
              ) : (
                ''
              )}
              {!data.IsEditable && !data.IsFreeGiftPopup ? (
                <p className='special-deal-message'>
                  <Text field={layoutData['Free Gift Hint']} />
                </p>
              ) : (
                ''
              )}
              {data.PromotionText !== '' ? <p className='special-deal-message'>{data.PromotionText}</p> : ''}
              {data.IsFreeGiftPopup && !data?.IsAutoAddFreeGiftPopup && !data.IsFreeGiftPopupCartPage ? (
                <span
                  className='cart-item-information__body__remove-btn special-deal-message edit-free-gift-btn'
                  onClick={handleOpenSelectingFreeGiftPopup}
                >
                  {pageName !== CART_PAGE_NAME ? <Text field={layoutData['Edit Label']} /> : <Text field={layoutData['Edit Button Text']} />}
                </span>
              ) : (
                <></>
              )}
              {data?.Type === PromotionType.AddOnDeals ? (
                <Text field={layoutData?.['Add On Deal Label']} tag='p' className='special-deal-message' />
              ) : (
                <></>
              )}
            </div>
            <div className='product-list__header'>
              <div className='product-list__header__information'>
                {showShippingAreaError && data.MissingShipping ? (
                  <div className='error-message'>{objMessages['Msg_014'].replace(NAME_REPLACE, data.DisplayName)}</div>
                ) : (
                  ''
                )}
                <h4 className='product-list__header__name'>{data.DisplayName}</h4>
                <div className='product-list__header__quantity'>
                  <Text field={layoutData['Delivery Quantity Label']} />: {data.Quantity}
                </div>
                <div className='product-list__header__status'>
                  {data.IsInStockProduct ? (
                    <span>
                      <Message messageCode='LBL-InStock' labelShipping={data['InStockDeliveryLabel']} />
                    </span>
                  ) : (
                    ''
                  )}
                  {data.IsOutStockProduct ? (
                    <span>
                      <Message messageCode='LBL-OutOfStock' labelShipping={data['OutStockDeliveryLabel']} />
                      .&nbsp;
                    </span>
                  ) : (
                    ''
                  )}
                  {data.IsPreOrderProduct ? (
                    <span>
                      <Message messageCode='LBL-PreOrder' labelShipping={data['PreOrderDeliveryLabel']} />
                      .&nbsp;
                    </span>
                  ) : (
                    ''
                  )}
                  {data.IsBackOrderProduct ? (
                    <span>
                      <Message messageCode='LBL-BackOrder' labelShipping={data['BackOrderDeliveryLabel']} />
                      .&nbsp;
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              {page === 'review' || page === 'balance-payment' ? (
                <div className='product-list__header__price'>
                  {isDeposit ? (
                    <span className='product-list__header__price__current'>{data?.Deposit?.DepositPriceWithCurrency}</span>
                  ) : (
                    <span className='product-list__header__price__current'>
                      {data.IsEditable ? data.LineTotal : <Message messageCode='LBL-Free' />}
                    </span>
                  )}
                  {isDeposit ? (
                    <>
                      <Text
                        field={layoutData?.['Deposit Tag Label']}
                        tag='span'
                        className='product-list__header__price__save smaller-text smaller-text--light'
                      />
                      <div className='product-list__header__price__box'>
                        <span className='product-list__header__price__current'>{data?.Deposit?.PreorderPriceWithCurrency}</span>
                        <Text
                          field={layoutData?.['Pre-order Offer Tag Label']}
                          tag='span'
                          className='product-list__header__price__save smaller-text smaller-text--light'
                        />
                      </div>
                    </>
                  ) : data.LineDiscountAmount !== 0 && !data.IsFreeGiftPopup ? (
                    <span className='product-list__header__price__save smaller-text smaller-text--light'>
                      <Text field={layoutData['Delivery You Saved Label']} /> {data.LineDiscount}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
            {data.SubLines && data.SubLines.length > 0 ? (
              <ol className='product-list__products'>
                {data.SubLines.map((item) => {
                  return (
                    <li className='product-list__products__item' key={item.ExternalCartLineId}>
                      <div className='product-list__products__item__information'>
                        <span>{item.DisplayName}</span>
                        <span>{item.VariantPropertyValue}</span>
                      </div>
                      {item.PromotionCartLines.map((promotionCartLine) => {
                        return promotionCartLine?.DisplayName ? (
                          <div
                            className={`${
                              promotionCartLine?.RelationshipId
                                ? 'product-list__products__item__additional-options'
                                : 'product-list__products__item__warranty'
                            } flex-name flex-name--space-between`}
                          >
                            <div
                              className={`${
                                promotionCartLine?.RelationshipId
                                  ? 'product-list__products__item__additional-options__name'
                                  : 'product-list__products__item__warranty__name'
                              } flex-name`}
                            >
                              {promotionCartLine?.RelationshipId ? (
                                <div className='product-info-icon product-info-icon--img'>
                                  <Image field={onGetIconHandler(promotionCartLine?.RelationshipId, layoutData)} />
                                </div>
                              ) : (
                                <div className='product-info-icon product-info-icon--warranty'></div>
                              )}
                              <span>
                                {promotionCartLine?.DisplayName} {+data.Quantity > 1 ? `x ${data.Quantity}` : ''}
                              </span>
                            </div>
                            {page === 'review' || page === 'balance-payment' ? (
                              <div className='product-list__products__item__warranty__price'>
                                {promotionCartLine?.LineFinalTotalAmount !== 0 ? (
                                  promotionCartLine?.LineFinalTotal
                                ) : (
                                  <Message messageCode='LBL-Free' />
                                )}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          ''
                        );
                      })}
                      {/* {
                          item.PromotionCartLines[0]?.DisplayName ? (
                            <div className='product-list__products__item__warranty flex-name'>
                              <div className='product-list__products__item__warranty__name flex-name'>
                                <div className='product-info-icon product-info-icon--warranty'></div>
                                <span>{item.PromotionCartLines[0]?.DisplayName} {+data.Quantity > 1 ? `x ${data.Quantity}` : ''}</span>
                              </div>
                              {page === 'review' ? (
                                <div className='product-list__products__item__warranty__price'>{item.PromotionCartLines[0]?.LineFinalTotalAmount !== 0 ? item.PromotionCartLines[0]?.LineFinalTotal : <Message messageCode='LBL-Free'/>}</div>
                              ) : (
                                ''
                              )}
                            </div>
                          ) : ''
                      } */}
                    </li>
                  );
                })}
                {data.PromotionFreeGift && data.PromotionFreeGift.length > 0 ? (
                  <li className='product-list__products__item'>
                    <div className='product-list__group'>
                      <div className='product-list__group__header flex-name flex-name--space-between'>
                        <div className='product-list__group__header__name flex-name'>
                          <div className='product-info-icon product-info-icon--free-gift'></div>
                          <span>
                            {layoutData['Delivery Free Gift Label'].value} {+data.Quantity > 1 ? `x ${data.Quantity}` : ''}
                          </span>
                        </div>
                        {page === 'review' || page === 'balance-payment' ? (
                          <div className='product-list__group__header__price'>
                            <Text field={layoutData['Delivery Free Label']} />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='product-list__group__body normal-text'>
                        {data.PromotionFreeGift.map((item) => {
                          return <p key={item.ExternalCartLineId}>{item.DisplayName}</p>;
                        })}
                      </div>
                    </div>
                  </li>
                ) : (
                  ''
                )}
              </ol>
            ) : (
              <>
                {data.VariantPropertyValue ? <div className='product-list__item__color'>{data.VariantPropertyValue}</div> : ''}
                {data.PromotionCartLines.map((item) => {
                  if (item.Type === PromotionType.Warranty) {
                    return (
                      <div className='product-list__group' key={item.ExternalCartLineId}>
                        <div className='product-list__group__header flex-name flex-name--space-between'>
                          <div className='product-list__group__header__name flex-name'>
                            <div className='product-info-icon product-info-icon--warranty'></div>
                            <span>
                              {item.DisplayName} {+data.Quantity > 1 ? `x ${data.Quantity}` : ''}
                            </span>
                          </div>
                          {page === 'review' ? (
                            <div className='product-list__group__header__price'>
                              {item.LineFinalTotalAmount !== 0 ? item.LineTotal : <Message messageCode='LBL-Free' />}
                            </div>
                          ) : page === 'balance-payment' ? (
                            <div className='product-list__group__header__price'>
                              {item.LinePriceWithQuantityAmount !== 0 ? item.LinePriceWithQuantity : <Message messageCode='LBL-Free' />}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (item.Type === PromotionType.AdditionalOptions) {
                    return (
                      <div className='product-list__group' key={item.ExternalCartLineId}>
                        <div className='product-list__group__header flex-name flex-name--space-between'>
                          <div className='product-list__group__header__name flex-name'>
                            <div className='product-info-icon product-info-icon--img'>
                              <Image field={onGetIconHandler(item?.RelationshipId, layoutData)} />
                            </div>
                            <span>
                              {item.DisplayName} {+data.Quantity > 1 ? `x ${data.Quantity}` : ''}
                            </span>
                          </div>
                          {page === 'review' || page === 'balance-payment' ? (
                            <div className='product-list__group__header__price'>
                              {item.LineFinalTotalAmount !== 0 ? item.LineTotal : <Message messageCode='LBL-Free' />}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (item.Type === PromotionType.Rental) {
                    return (
                      <div className='product-list__group' key={item.ExternalCartLineId}>
                        <div className='product-list__group__header flex-name flex-name--space-between'>
                          <div className='product-list__group__header__name flex-name'>
                            <div className='product-info-icon product-info-icon--rental'></div>
                            <span>
                              {item.DisplayName} {+data.Quantity > 1 ? `x ${data.Quantity}` : ''}
                            </span>
                          </div>
                          {page === 'review' || page === 'balance-payment' ? (
                            <div className='product-list__group__header__price'>
                              {item.LineFinalTotalAmount !== 0 ? item.LineTotal : <Message messageCode='LBL-Free' />}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (item.Type === PromotionType.TradeIn || item.Type === PromotionType.TradeInLoyalty) {
                    return (
                      <div className='product-list__group' key={item.ExternalCartLineId}>
                        <div className='product-list__group__header'>
                          <div className='product-list__group__header__group flex-name flex-name--space-between cccc'>
                            <div className='product-list__group__header__name flex-name'>
                              <div className='product-info-icon product-info-icon--trade-in'></div>
                              <span className='option-name'>{item.DisplayName}</span>
                            </div>
                            {page === 'review' ? (
                              <div className='product-list__group__header__price'>{item.TradeInProductPrice}</div>
                            ) : page === 'balance-payment' ? (
                              <div className='product-list__group__header__price'>
                                {item.LinePriceWithQuantityAmount !== 0 ? item.LinePriceWithQuantity : <Message messageCode='LBL-Free' />}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          <span className='link-text-underline link-text-underline--lg' onClick={() => setShowModal(true)}>
                            {item.TradeInProductBrand} - {item.TradeInProductName} - {item.TradeInProductModel}
                          </span>
                        </div>
                        <ModalComponent isShow={isShowModal} cssClass={'tradein-popup'} onCloseHandler={() => setShowModal(false)} param={params}>
                          <div className='tradein-content-modal'>
                            <div className='tradein-content-modal__image'>
                              {item.TradeInImage?.length ? (
                                item?.TradeInImage.map((image, index) => (
                                  <div className='image-uploaded' key={index}>
                                    <div className='image-uploaded__content'>
                                      <img alt='trade in upload image' src={image.url} />
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <></>
                              )}
                            </div>
                            <h3 className='tradein-content-modal__text'>
                              {item.TradeInProductBrand} - {item.TradeInProductName} - {item.TradeInProductModel}
                            </h3>
                          </div>
                        </ModalComponent>
                      </div>
                    );
                  }
                })}
                {data.PromotionFreeGift && data.PromotionFreeGift.length > 0 ? (
                  <div className='product-list__group'>
                    <div className='product-list__group__header flex-name flex-name--space-between'>
                      <div className='product-list__group__header__name flex-name'>
                        <div className='product-info-icon product-info-icon--free-gift'></div>
                        <span>
                          {layoutData['Delivery Free Gift Label'].value} {+data.Quantity > 1 ? `x ${data.Quantity}` : ''}
                        </span>
                      </div>
                      {page === 'review' || page === 'balance-payment' ? (
                        <div className='product-list__group__header__price'>
                          <Text field={layoutData['Delivery Free Label']} />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className='product-list__group__body normal-text'>
                      {data.PromotionFreeGift.map((item) => {
                        return <p key={item.ExternalCartLineId}>{item.DisplayName}</p>;
                      })}
                    </div>
                  </div>
                ) : data.DepositPromotionCartLines.length > 0 ? (
                  <ProductWithDepositFreeGift layoutData={layoutData} page={page} data={data} />
                ) : (
                  <></>
                )}
              </>
            )}
            {data.GiftCardId || data.GiftWrapId ? (
              <div className='product-list__group'>
                <div className='product-list__group__header flex-name flex-name--space-between'>
                  <div className='product-list__group__header__name flex-name'>
                    <div className='product-info-icon product-info-icon--gifting'></div>
                    <span>
                      <Text field={layoutData['Gifting Option Label']} /> {data.Quantity > 1 ? <>{`x ${data.Quantity}`}</> : ''}
                    </span>
                  </div>
                  {page === 'review' || page === 'balance-payment' ? (
                    <div className='product-list__group__header__price'>
                      {data.GiftingOptionTotal && data.GiftingOptionTotal !== DEFAULT_PRICE ? (
                        data.GiftingOptionTotal
                      ) : (
                        <Text field={layoutData['Gifting Option Free Label']} />
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className='product-list__group__body normal-text'>
                  {data.GiftWrapId && getInfoGift(data.GiftWrapId, data.GiftingOptionLines) && (
                    <p>
                      {getInfoGift(data.GiftWrapId, data.GiftingOptionLines)?.DisplayName} <Text field={layoutData['Gift Wrap Label']} />
                    </p>
                  )}
                  {data.GiftCardId && getInfoGift(data.GiftCardId, data.GiftingOptionLines) && (
                    <>
                      <p>
                        {getInfoGift(data.GiftCardId, data.GiftingOptionLines)?.DisplayName} <Text field={layoutData['Gift Card Label']} />
                      </p>
                      {data.GiftCardMessage ? <p className='smaller-text smaller-text--lighter'>{decodeURI(data.GiftCardMessage)}</p> : ''}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {page === 'review' ? (
              <>
                {isDeposit ? (
                  <>
                    <div className='product-list__deposit'>
                      <div className='product-list__deposit__item'>
                        <Text field={layoutData['Delivery Subtotal Label']} tag='span' />
                      </div>
                      <div className='product-list__deposit__item product-list__deposit__item--block'>
                        <span>{`${layoutData?.['Deposit Payment Label']?.value}: `}</span>
                        <span>{depositOrderStatusText}</span>
                      </div>
                      <div className='product-list__deposit__item'>
                        <Text field={layoutData?.['Deposit Paid Label']} tag='span' />
                        <span>{data?.Deposit?.DepositPriceWithCurrency}</span>
                      </div>
                      {data.PromotionCartLines.map((item) => {
                        if (item.Type === PromotionType.Warranty) {
                          return (
                            <div className='product-list__deposit__item'>
                              <Text field={layoutData?.['Extended Warranty Label']} tag='span' />
                              <span>{item.LineFinalTotalAmount !== 0 ? item.LineTotal : <Message messageCode='LBL-Free' />}</span>
                            </div>
                          );
                        }
                      })}
                      {data?.PromotionCartLines?.length && data?.PromotionCartLines?.find((item) => item?.Type === DEPOSIT_FREEGIFT_TYPE) ? (
                        <>
                          <div className='product-list__deposit__item product-list__deposit__item--align-item-top'>
                            <p className='product-list__deposit__item__box'>
                              <Text field={layoutData?.['Deposit Free Gift Label']} tag='span' />
                              {freeGiftNoteText?.value ? (
                                <>
                                  <Text field={{ value: '-' }} tag='span' />
                                  <Text className='product-list__deposit__item--color-orange' field={freeGiftNoteText} tag='span' />
                                </>
                              ) : (
                                <></>
                              )}
                            </p>
                            <Text field={layoutData?.['Delivery Free Label']} tag='span' />
                          </div>
                          <div className='product-list__deposit__item product-list__deposit__item--free-gift'>
                            <span>{data?.PromotionCartLines?.find((item) => item?.Type === DEPOSIT_FREEGIFT_TYPE).DisplayName}</span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className='product-list__deposit__item product-list__deposit__item--fwRegular product-list__deposit__item--border-top'>
                        <Text field={layoutData?.['Total Deposit Label']} tag='span' />
                        <span>{data?.Deposit?.TotalDepositWithCurrency}</span>
                      </div>
                      <div className='product-list__deposit__item product-list__deposit__item--border-top-mobile product-list__deposit__item--block'>
                        <span>{`${layoutData?.['Balance Label']?.value}: `}</span>
                        {layoutData?.['From To Date Label']?.value ? <span>{payBalanceOrderStatusText}</span> : <></>}
                      </div>
                      <div className='product-list__deposit__item'>
                        <Text field={layoutData?.['Balance Payment Label']} tag='span' />
                        <span>{data?.Deposit?.BalancePriceWithCurrency}</span>
                      </div>
                      <div className='product-list__deposit__item'>
                        <Text field={layoutData?.['Discount Label']} tag='span' />
                        {data?.Deposit?.DiscountWithCurrency}
                      </div>
                      {data?.PromotionCartLines?.length && data?.PromotionCartLines?.find((item) => item?.Type === BALANCE_FREEGIFT_TYPE) ? (
                        <>
                          <div className='product-list__deposit__item product-list__deposit__item--align-item-top'>
                            <p className='product-list__deposit__item__box'>
                              <Text field={layoutData?.['Deposit Free Gift Label']} tag='span' />
                              {freeGiftNoteText?.value ? (
                                <>
                                  <Text field={{ value: '-' }} tag='span' />
                                  <Text className='product-list__deposit__item--color-orange' field={freeGiftNoteText} tag='span' />
                                </>
                              ) : (
                                <></>
                              )}
                            </p>
                            <Text field={layoutData?.['Delivery Free Label']} tag='span' />
                          </div>
                          <div className='product-list__deposit__item product-list__deposit__item--free-gift'>
                            <span>{data?.PromotionCartLines?.find((item) => item?.Type === BALANCE_FREEGIFT_TYPE).DisplayName}</span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className='product-list__deposit__item product-list__deposit__item--border-top product-list__deposit__item--fwRegular'>
                        <Text field={layoutData?.['Total Balance Payment Label']} />
                        <span>{data?.Deposit?.TotalBalancePriceWithCurrency}</span>
                      </div>
                    </div>
                    {currentCart?.OrderStatus === 'PendingBalancePayment' ? (
                      <div className='product-list__button-pay-balance'>
                        <Link
                          field={{ href: `${layoutData?.['PayBalance Button Link']?.value?.href}?OrderId=${data?.OrderId}` }}
                          className={`btn btn-primary ${isButtonPayBalance ? '' : 'disabled'}`}
                        >
                          <Text field={layoutData?.['PayBalance Button Text']} />
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <div className='product-list__subtotal flex-name flex-name--space-between'>
                    <p>
                      <Text field={layoutData['Delivery Subtotal Label']} />
                    </p>
                    <p>{data.LineFinalTotalAmount !== 0 ? data.LineFinalTotal : <Message messageCode='LBL-Free' />}</p>
                  </div>
                )}
              </>
            ) : page === 'balance-payment' ? (
              <>
                <div className='product-list__deposit'>
                  <div className='product-list__deposit__item'>
                    <Text field={layoutData['Delivery Subtotal Label']} tag='span' />
                  </div>
                  <div className='product-list__deposit__item product-list__deposit__item--block'>
                    <span>{`${layoutData?.['Deposit Payment Label']?.value}: `}</span>
                    <span>{completedText}</span>
                  </div>
                  <div className='product-list__deposit__item'>
                    <Text field={layoutData?.['Deposit Paid Label']} tag='span' />
                    <span>{data?.Deposit?.DepositPriceWithCurrency}</span>
                  </div>
                  {data.PromotionCartLines.map((item) => {
                    if (item.Type === PromotionType.Warranty) {
                      return (
                        <div className='product-list__deposit__item'>
                          <Text field={layoutData?.['Extended Warranty Label']} tag='span' />
                          <span>{item.LinePriceWithQuantityAmount !== 0 ? item.LinePriceWithQuantity : <Message messageCode='LBL-Free' />}</span>
                        </div>
                      );
                    }
                  })}
                  {data?.PromotionCartLines?.length && data?.PromotionCartLines?.find((item) => item?.Type === DEPOSIT_FREEGIFT_TYPE) ? (
                    <>
                      <div className='product-list__deposit__item product-list__deposit__item--align-item-top'>
                        <p className='product-list__deposit__item__box'>
                          <Text field={layoutData?.['Deposit Free Gift Label']} tag='span' />
                          {freeGiftNoteText?.value ? (
                            <>
                              <Text field={{ value: '-' }} tag='span' />
                              <Text className='product-list__deposit__item--color-orange' field={freeGiftNoteText} tag='span' />
                            </>
                          ) : (
                            <></>
                          )}
                        </p>
                        <Text field={layoutData?.['Delivery Free Label']} tag='span' />
                      </div>
                      <div className='product-list__deposit__item product-list__deposit__item--free-gift'>
                        <span>{data?.PromotionCartLines?.find((item) => item?.Type === DEPOSIT_FREEGIFT_TYPE).DisplayName}</span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className='product-list__deposit__item product-list__deposit__item--fwRegular product-list__deposit__item--border-top'>
                    <Text field={layoutData?.['Total Deposit Label']} tag='span' />
                    <span>{data?.Deposit?.TotalDepositWithCurrency}</span>
                  </div>
                  <div className='product-list__deposit__item product-list__deposit__item--border-top-mobile product-list__deposit__item--block'>
                    <span>{`${layoutData?.['Balance Label'].value}: `}</span>
                    <span>
                      {`${(layoutData?.['From To Date Label']?.value)
                        .replace('{StartDate}', global.formatDateDDMMMYYYY(data?.Deposit?.BalanceStartDate))
                        .replace('{EndDate}', global.formatDateDDMMMYYYY(data?.Deposit?.BalanceEndDate))}`}
                    </span>
                  </div>
                  <div className='product-list__deposit__item'>
                    <Text field={layoutData?.['Balance Payment Label']} tag='span' />
                    <span>{data?.Deposit?.BalancePriceWithCurrency}</span>
                  </div>
                  <div className='product-list__deposit__item'>
                    <Text field={layoutData?.['Discount Label']} tag='span' />
                    {data?.Deposit?.DiscountWithCurrency}
                  </div>
                  {data?.PromotionCartLines?.length && data?.PromotionCartLines?.find((item) => item?.Type === BALANCE_FREEGIFT_TYPE) ? (
                    <>
                      <div className='product-list__deposit__item product-list__deposit__item--align-item-top'>
                        <p className='product-list__deposit__item__box'>
                          <Text field={layoutData?.['Deposit Free Gift Label']} tag='span' />
                          {freeGiftNoteText?.value ? (
                            <>
                              <Text field={{ value: '-' }} tag='span' />
                              <Text className='product-list__deposit__item--color-orange' field={freeGiftNoteText} tag='span' />
                            </>
                          ) : (
                            <></>
                          )}
                        </p>
                        <Text field={layoutData?.['Delivery Free Label']} tag='span' />
                      </div>
                      <div className='product-list__deposit__item product-list__deposit__item--free-gift'>
                        <span>{data?.PromotionCartLines?.find((item) => item?.Type === BALANCE_FREEGIFT_TYPE).DisplayName}</span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className='product-list__deposit__item product-list__deposit__item--border-top product-list__deposit__item--fwRegular'>
                    <Text field={layoutData?.['Total Balance Payment Label']} />
                    <span>{data?.Deposit?.TotalBalancePriceWithCurrency}</span>
                  </div>
                </div>

                {/* <div className='product-list__button-pay-balance'>
                  <button className='btn btn-outline-primary' onClick={onShowHandler}>
                    <Text field={layoutData?.['Remove Label']}/>
                  </button>
                </div> */}
                {/* <BalanceDetailModals isShow={isShow} onCloseHandler={onCloseHandler} onlayoutData={layoutData} onExternalCartLineId={data?.ExternalCartLineId}/> */}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    )
  );
};

ProductListItem.propTypes = {
  type: PropTypes.string,
  data: PropTypes.any,
  page: PropTypes.string,
  layoutData: PropTypes.any
};

export default ProductListItem;
