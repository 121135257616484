import { array } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { deleteTimeSlotOfDateResponse, getTotalBookedDateOfStore } from '@redux/actions/bookingExperience/getTotalBookedTimeSlot';
import { getStoreLocator } from '@redux/actions/storeLocator';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useQuery } from '@utils/customsHook/useQuery';

import { useDisplayFormikMessage } from '../../../hooks/useDisplayFormikMessage';
import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';
import { useScheduleAppointmentFormContext } from '../index';
import SearchScheduleFilterStoreBar from './searchScheduleFilterStoreBar';
import StoreInfoItem from './StoreInfoItem';

const SelectStoreSchedule = ({ searchResultList }) => {
  const dispatch = useDispatch();
  const { layoutData } = useScheduleAppointmentContext();
  const { formik } = useScheduleAppointmentFormContext();
  const displayFormikMessage = useDisplayFormikMessage();
  const [keyword, setKeyWord] = useState('');
  const [filterAreaValue, setFilterAreaValue] = useState(null);
  const query = useQuery();
  const history = useHistory();

  const storeData = useMemo(() => {
    const filterData = searchResultList.filter((store) =>
      filterAreaValue ? Object.entries(filterAreaValue).every(([key, value]) => store?.[key] === value) : true
    );

    if (keyword && filterAreaValue) return [...filterData].filter((store) => store?.Name?.toLowerCase()?.includes(keyword));
    if (filterAreaValue) return [...filterData];

    return searchResultList.filter((store) => store?.Name?.toLowerCase().includes(keyword));
  }, [searchResultList, keyword, filterAreaValue]);

  useEffect(() => {
    dispatch(getStoreLocator());
  }, []);
  const handleSearchStore = (keyword) => setKeyWord(keyword);

  const handleFilterArea = (params) => {
    setFilterAreaValue(params);
  };
  const handleClearFilter = () => setFilterAreaValue(null);

  const handleFetchTotalBookedDateOfStore = (storeId) => {
    dispatch(getTotalBookedDateOfStore({ storeId: storeId }));

    dispatch(deleteTimeSlotOfDateResponse());
  };

  const handleSelectStore = (event) => {
    handleFetchTotalBookedDateOfStore(event.target.value);

    query.delete('storeId');

    history.push({ search: query.toString() });

    formik.setFieldValue('storeId', event.target.value);

    const fieldsToReset = ['date', 'timeSlot', 'guest'];

    fieldsToReset.forEach((field) => {
      formik.setFieldValue(field, '');
    });
  };

  return (
    <div className='select-store__content__list'>
      <Text className='select-store__content__list__title' tag='h4' field={layoutData['Select Store Label']} />
      <div className='select-store__content__list__group'>
        <SearchScheduleFilterStoreBar onSearch={handleSearchStore} onFilter={handleFilterArea} onClearFilterArea={handleClearFilter} />
        {storeData.length ? (
          storeData.map((store) => (
            <StoreInfoItem
              key={store.ItemId}
              storeData={store}
              handleSelectStore={handleSelectStore}
              handleFetchTotalBookedDateOfStore={handleFetchTotalBookedDateOfStore}
            />
          ))
        ) : (
          <></>
        )}
      </div>
      <span className='error-validate'>{displayFormikMessage(formik, 'storeId')}</span>
    </div>
  );
};

SelectStoreSchedule.propTypes = {
  searchResultList: array
};

const mapStateToProps = (state) => {
  return {
    searchResultList: state.storeLocatorReducer.allStoreReducer.searchResultList || []
  };
};

export default connect(mapStateToProps)(SelectStoreSchedule);
