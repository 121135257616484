import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap } from 'rxjs/operators';

import {
  VIEW_OFFER_DETAIL_CART_ID_KEY
} from '@components/PersonalizedOffer/hooks/useGenerateViewOfferDetailLink';
import CartDetailApiModel from '@components/SlicingPage/CartDetails/models/CartDetailApiModel';
import CartStripAdsApiModel from '@components/SlicingPage/CartDetails/models/CartStripAdsApiModel';
import PickUpPointsModel from '@components/SlicingPage/CartDetails/SFExpressPopup/models/PickUpPointsModel';
import * as buyingOptionsAction from '@redux/actions/buyingOptions';
import * as buyingOptionsTypes from '@redux/actions/buyingOptions/buyingOptionsTypes';
import * as actionsCart from '@redux/actions/cartDetail';
import * as cartTypes from '@redux/actions/cartDetail/cartTypes';
import { getCPCustomer } from '@redux/actions/clientTellingPortalCustomer';
import * as couponAction from '@redux/actions/coupon';
import { getClientSOCartSuccess } from '@redux/actions/personalizeOffer/getClientSOCart';
import * as actions from '@redux/actions/product';
import * as productTypes from '@redux/actions/product/productTypes';
import { get, getAsObservable, post, postAsObservable } from '@services/genericService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { DELIVERY_OPTION } from '@utils/constant';
import global from '@utils/global';
import { deepCopy } from '@utils/utility';

import { ADD_TO_SO_CART_V2 } from '../../../actions/product/productTypes';
import {getCartIdParam} from '../../personalizeOffer/utils';
import { ACTION_UPLOAD_LIST_FILES } from '../../productReview/config';
import * as config from './config';
import { ADD_TO_SO_CART_V2_ENDPOINT } from './config';
import CartDetailService from './service';
import { getCartNameValue } from './utils';

export const getCurrentCartEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.GET_CURRENT_CART),
    switchMap(() => {
      return getAsObservable(config.GET).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            let filteredLineItem = [];

            const fullPath = window.location.href;

            const cartLineId = global.getQueryParams(fullPath, 'cartLineId') || null;

            const cartDetailApiModel = new CartDetailApiModel().getData(deepCopy(res.data));
            if (cartLineId) {
              const filterItem = cartDetailApiModel?.Lines?.filter((item) => item?.ExternalCartLineId === cartLineId)[0];

              filteredLineItem = filterItem ? [filterItem] : [];
            }

            return actions.getCurrentCartSuccess(CartDetailService.customCurrentCart(res.data), filteredLineItem);
          } else {
            return actions.getCurrentCartFailed({
              errorMessage: res.data?.Errors?.[0] || 'Failed',
              failureCode: res.data?.Data?.CommandStatuses || []
            });
          }
        }),
        catchError((error) => of(actions.getCurrentCartFailed({ errorMessage: error.message || 'Failed' })))
      );
    })
  );

export const getCurrentSOCartEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.GET_CURRENT_SO_CART),
    switchMap(() => {
      const cartName = getCartNameValue();
      const params = {};
      let getCartEndpoint = config.GET_CP;
      if (cartName) {
        params.name = cartName;

        getCartEndpoint = config.GET_CP_SO_CART_DETAIL;
      }

      return from(get(getCartEndpoint, params)).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            let filteredLineItem = [];

            const fullPath = window.location.href;

            const cartLineId = global.getQueryParams(fullPath, 'cartLineId') || null;

            const cartDetailApiModel = new CartDetailApiModel().getData(deepCopy(res.data));
            if (cartLineId) {
              const filterItem = cartDetailApiModel?.Lines?.filter((item) => item?.ExternalCartLineId === cartLineId)[0];

              filteredLineItem = filterItem ? [filterItem] : [];
            }

            return actions.getCurrentSOCartSuccess(CartDetailService.customCurrentCart(res.data), filteredLineItem);
          } else {
            return actions.getCurrentSOCartFailed({
              errorMessage: res.data?.Errors?.[0] || 'Failed',
              failureCode: res.data?.Data?.CommandStatuses || []
            });
          }
        }),
        catchError((error) => of(actions.getCurrentSOCartFailed({ errorMessage: error.message || 'Failed' })))
      );
    })
  );

export const saveSOcartEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.SAVE_SO_CART),
    switchMap((action) => {
      const cartName = getCartNameValue();

      const params = {
        ExpiredDate: action.payload.ExpiredDate,
        Status: action.payload.Status
      };
      if (cartName) params.CartName = cartName;

      return postAsObservable(config.SAVE_SO_CART, params).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.saveSOCartSuccess(CartDetailService.customCurrentCart(res.data));
          } else {
            return actions.saveSOCartFailed(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(actions.saveSOCartFailed(error || 'Failed')))
      );
    })
  );

export const addToCartEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.ADD_TO_CART),
    switchMap((action) => {
      return postAsObservable(config.POST, action.payload).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.addToCartSuccess(CartDetailService.customCurrentCart(res.data));
          } else {
            return actions.addToCartFailed(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(actions.addToCartFailed(error || 'Failed')))
      );
    })
  );

export const addToCartV2Epic = (action$) =>
  action$.pipe(
    ofType(productTypes.ADD_TO_CART_V2),
    switchMap((action) => {
      return postAsObservable(config.ADD_TO_CART_V2_ENDPOINT, action.payload).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.addToCartSuccess(CartDetailService.customCurrentCart(res.data));
          } else {
            return actions.addToCartFailed(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(actions.addToCartFailed(error || 'Failed')))
      );
    })
  );

export const addToSOCartEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.ADD_TO_SO_CART),
    switchMap((action) => {
      const cartName = getCartNameValue();

      const params = {
        ...action.payload
      };
      if (cartName) params.cartName = cartName;

      return postAsObservable(config.POST_CP, params).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.addToSOCartSuccess(CartDetailService.customCurrentCart(res.data));
          } else {
            return actions.addToSOCartFailed(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(actions.addToSOCartFailed(error || 'Failed')))
      );
    })
  );

export const removeCartEpic = (action$) =>
  action$.pipe(
    ofType(cartTypes.REMOVE_CART_LINE),
    switchMap((action) => {
      const params = {
        cartLineId: action.payload.cartLineId
      };

      return getAsObservable(config.ACTION_REMOVE_CART, params).pipe(
        concatMap((res) => {
          let tempAction;
          if (res.status === 200 && res.data?.Success) {
            tempAction = actionsCart.removeCartLineSuccess(CartDetailService.customCurrentCart(res.data));
          } else {
            tempAction = actionsCart.removeCartLineFailed(res.data?.Errors?.[0] || 'Failed');
          }

          return merge(
            of(tempAction),
            from(get(config.BROWSE)).pipe(
              concatMap((res) => {
                let actionApply;

                if (res.status === 200) {
                  actionApply = couponAction.getBrowseCouponListSuccess(deepCopy(res.data));
                } else {
                  actionApply = couponAction.getBrowseCouponListFailed('Failed');
                }

                return merge(
                  of(actionApply),
                  from(get(config.GET_APPLIED)).pipe(
                    map((res) => {
                      if (res.status === 200) {
                        return couponAction.getAppliedCouponListSuccess(deepCopy(res.data));
                      } else {
                        return couponAction.getAppliedCouponListFailed('Failed');
                      }
                    })
                  )
                );
              })
            )
          );
        })
      );
    })
  );

export const removeSOCartEpic = (action$) =>
  action$.pipe(
    ofType(cartTypes.REMOVE_SO_CART_LINE),
    switchMap((action) => {
      const cartName = getCartNameValue();

      const params = {
        cartLineId: action.payload.cartLineId
      };
      if (cartName) params.cartName = cartName;

      return getAsObservable(config.ACTION_REMOVE_SO_CART, params).pipe(
        concatMap((res) => {
          let tempAction;
          if (res.status === 200 && res.data?.Success) {
            tempAction = actionsCart.removeSOCartLineSuccess(CartDetailService.customCurrentCart(res.data));
          } else {
            tempAction = actionsCart.removeSOCartLineFailed(res.data?.Errors?.[0] || 'Failed');
          }

          return merge(
            of(tempAction),
            from(get(config.BROWSE)).pipe(
              concatMap((res) => {
                let actionApply;

                if (res.status === 200) {
                  actionApply = couponAction.getBrowseCouponListSuccess(deepCopy(res.data));
                } else {
                  actionApply = couponAction.getBrowseCouponListFailed('Failed');
                }

                return merge(
                  of(actionApply),
                  from(get(config.GET_APPLIED)).pipe(
                    map((res) => {
                      if (res.status === 200) {
                        return couponAction.getAppliedCouponListSuccess(deepCopy(res.data));
                      } else {
                        return couponAction.getAppliedCouponListFailed('Failed');
                      }
                    })
                  )
                );
              })
            )
          );
        })
      );
    })
  );

function isFulfillmentType(currentLine, deliveryType) {
  return (
    currentLine?.DeliveryOptionId &&
    currentLine?.DeliveryOptionId ==
      currentLine?.DeliveryOptions.find((deliveryItem) => deliveryItem.ShippingOptionType.Value === deliveryType)?.ExternalId
  );
}

function isSfExpressOnly(currentLine) {
  return (
    currentLine?.DeliveryOptions &&
    currentLine?.DeliveryOptions.find((deliveryItem) => deliveryItem.ShippingOptionType.Value === DELIVERY_OPTION.SFExpress)
  );
}

function shouldSetDefaultAddress(lineReturnedFromApi) {
  if (!lineReturnedFromApi) return false;
  let shouldSetDefaultAddress = false;
  if (lineReturnedFromApi) {
    let isDeliverySfExpressLine = isFulfillmentType(lineReturnedFromApi, DELIVERY_OPTION.DeliveryBySFExpress);
    let isDelivery = isFulfillmentType(lineReturnedFromApi, DELIVERY_OPTION.ShipItem);
    let noDeliveryOptionYet = !lineReturnedFromApi.DeliveryOptionId;
    let sfExpressOnly = isSfExpressOnly(lineReturnedFromApi);

    shouldSetDefaultAddress =
      !sfExpressOnly && (noDeliveryOptionYet || isDeliverySfExpressLine || isDelivery) && !lineReturnedFromApi.Address?.ExternalId;
  }

  return shouldSetDefaultAddress;
}

export const updateCartEpic = (action$) =>
  action$.pipe(
    ofType(cartTypes.UPDATE_CART_LINE),
    switchMap((action) => {
      const params = {
        Lines: action.payload.Lines
      };

      return postAsObservable(config.ACTION_UPDATE_CART, params).pipe(
        concatMap((res) => {
          let updateCart;

          if (res.status === 200 && res.data?.Success) {
            updateCart = actionsCart.updateCartLineSuccess(CartDetailService.customCurrentCart(res.data));
          } else {
            updateCart = actionsCart.updateCartLineFailed({
              errorMessage: res.data?.Errors?.[0] || 'Failed',
              failureCode: res.data?.Data?.CommandStatuses || []
            });
          }
          let lineReturnedFromApi = res.data?.Lines?.find((line) => line?.ExternalCartLineId == params?.Lines?.[0].LineUniqueId);
          let setDefaultAddress = shouldSetDefaultAddress(lineReturnedFromApi);
          if (setDefaultAddress) {
            return merge(
              of(updateCart),
              from(
                postAsObservable(config.GET_CART_DELIVERY, {
                  deliveryOptionId: params?.Lines?.[0].DeliveryOptionId || '',
                  cartLineIds: [params?.Lines?.[0].LineUniqueId] || []
                })
              ).pipe(
                mergeMap((res) => {
                  let defaultShippingAddress;
                  if (res.status === 200) {
                    defaultShippingAddress = actions.getCurrentCartSuccess(CartDetailService.customCurrentCart(res.data), []);
                  }

                  return merge(
                    of(defaultShippingAddress),
                    from(get(config.GET_GIFTING_OPTION_LINK)).pipe(
                      mergeMap((res) => {
                        let giftingOptionApply;
                        if (res.status === 200) {
                          giftingOptionApply = actions.getGiftingOptionSuccess(CartDetailService.customGiftingOptions(res.data));
                        }

                        return merge(
                          of(giftingOptionApply),
                          from(get(config.BROWSE)).pipe(
                            concatMap((res) => {
                              let actionApply;

                              if (res.status === 200) {
                                actionApply = couponAction.getBrowseCouponListSuccess(deepCopy(res.data));
                              } else {
                                actionApply = couponAction.getBrowseCouponListFailed('Failed');
                              }

                              return merge(
                                of(actionApply),
                                from(get(config.GET_APPLIED)).pipe(
                                  map((res) => {
                                    if (res.status === 200) {
                                      return couponAction.getAppliedCouponListSuccess(deepCopy(res.data));
                                    } else {
                                      return couponAction.getAppliedCouponListFailed('Failed');
                                    }
                                  })
                                )
                              );
                            })
                          )
                        );
                      })
                    )
                  );
                })
              )
            );
          } else
            return merge(
              of(updateCart),
              from(get(config.GET_GIFTING_OPTION_LINK)).pipe(
                mergeMap((res) => {
                  let giftingOptionApply;
                  if (res.status === 200) {
                    giftingOptionApply = actions.getGiftingOptionSuccess(CartDetailService.customGiftingOptions(res.data));
                  }

                  return merge(
                    of(giftingOptionApply),
                    from(get(config.BROWSE)).pipe(
                      concatMap((res) => {
                        let actionApply;

                        if (res.status === 200) {
                          actionApply = couponAction.getBrowseCouponListSuccess(deepCopy(res.data));
                        } else {
                          actionApply = couponAction.getBrowseCouponListFailed('Failed');
                        }

                        return merge(
                          of(actionApply),
                          from(get(config.GET_APPLIED)).pipe(
                            map((res) => {
                              if (res.status === 200) {
                                return couponAction.getAppliedCouponListSuccess(deepCopy(res.data));
                              } else {
                                return couponAction.getAppliedCouponListFailed('Failed');
                              }
                            })
                          )
                        );
                      })
                    )
                  );
                })
              )
            );
        })
      );
    })
  );

export const updateSOCartEpic = (action$) =>
  action$.pipe(
    ofType(cartTypes.UPDATE_SO_CART_LINE),
    switchMap((action) => {
      const cartName = getCartNameValue();

      const params = {
        Lines: action.payload.Lines
      };
      if (cartName) params.CartName = cartName;

      return postAsObservable(config.ACTION_UPDATE_SO_CART, params).pipe(
        concatMap((res) => {
          let updateCart;

          if (res.status === 200 && res.data?.Success) {
            updateCart = actionsCart.updateSOCartLineSuccess(CartDetailService.customCurrentCart(res.data));
          } else {
            updateCart = actionsCart.updateSOCartLineFailed({
              errorMessage: res.data?.Errors?.[0] || 'Failed',
              failureCode: res.data?.Data?.CommandStatuses || []
            });
          }
          let lineReturnedFromApi = res.data?.Lines?.find((line) => line?.ExternalCartLineId == params?.Lines?.[0].LineUniqueId);
          let setDefaultAddress = shouldSetDefaultAddress(lineReturnedFromApi);
          if (setDefaultAddress) {
            const setDefaultAddressParams = {
              deliveryOptionId: params?.Lines?.[0].DeliveryOptionId || '',
              cartLineIds: [params?.Lines?.[0].LineUniqueId] || []
            };
            if (cartName) setDefaultAddressParams.cartId = cartName;
            if (res?.data?.Name) setDefaultAddressParams.cartId = res?.data?.Name;

            return merge(
              of(updateCart),
              from(postAsObservable(config.GET_CART_DELIVERY, setDefaultAddressParams)).pipe(
                mergeMap((res) => {
                  let defaultShippingAddress;
                  if (res.status === 200) {
                    defaultShippingAddress = actions.getCurrentCartSuccess(CartDetailService.customCurrentCart(res.data), []);
                  }

                  return merge(
                    of(defaultShippingAddress),
                    from(get(config.GET_GIFTING_OPTION_LINK)).pipe(
                      mergeMap((res) => {
                        let giftingOptionApply;
                        if (res.status === 200) {
                          giftingOptionApply = actions.getGiftingOptionSuccess(CartDetailService.customGiftingOptions(res.data));
                        }

                        return merge(
                          of(giftingOptionApply),
                          from(get(config.BROWSE)).pipe(
                            concatMap((res) => {
                              let actionApply;

                              if (res.status === 200) {
                                actionApply = couponAction.getBrowseCouponListSuccess(deepCopy(res.data));
                              } else {
                                actionApply = couponAction.getBrowseCouponListFailed('Failed');
                              }

                              return merge(
                                of(actionApply),
                                from(get(config.GET_APPLIED)).pipe(
                                  map((res) => {
                                    if (res.status === 200) {
                                      return couponAction.getAppliedCouponListSuccess(deepCopy(res.data));
                                    } else {
                                      return couponAction.getAppliedCouponListFailed('Failed');
                                    }
                                  })
                                )
                              );
                            })
                          )
                        );
                      })
                    )
                  );
                })
              )
            );
          } else
            return merge(
              of(updateCart),
              from(get(config.GET_GIFTING_OPTION_LINK)).pipe(
                mergeMap((res) => {
                  let giftingOptionApply;
                  if (res.status === 200) {
                    giftingOptionApply = actions.getGiftingOptionSuccess(CartDetailService.customGiftingOptions(res.data));
                  }

                  return merge(
                    of(giftingOptionApply),
                    from(get(config.BROWSE)).pipe(
                      concatMap((res) => {
                        let actionApply;

                        if (res.status === 200) {
                          actionApply = couponAction.getBrowseCouponListSuccess(deepCopy(res.data));
                        } else {
                          actionApply = couponAction.getBrowseCouponListFailed('Failed');
                        }

                        return merge(
                          of(actionApply),
                          from(get(config.GET_APPLIED)).pipe(
                            map((res) => {
                              if (res.status === 200) {
                                return couponAction.getAppliedCouponListSuccess(deepCopy(res.data));
                              } else {
                                return couponAction.getAppliedCouponListFailed('Failed');
                              }
                            })
                          )
                        );
                      })
                    )
                  );
                })
              )
            );
        })
      );
    })
  );

export const setCartFullfillment = (action$) =>
  action$.pipe(
    ofType(cartTypes.SET_CART_FULLFILLMENT),
    switchMap((action) => {
      if (action.payload.reloadPromotions) {
        return postAsObservable(config.SET_FULLFILLMENT, action.payload.setFulfillmentPayload).pipe(
          concatMap((res) => {
            let getCurrentCart;
            let getClientSOCart = {
              type: '',
              payload: {}
            };

            if (res.status === 200 && res.data?.Success) {
              getCurrentCart = actions.getCurrentCartSuccess(CartDetailService.customCurrentCart(res.data), []);

              getClientSOCart = getClientSOCartSuccess({ OsimCart: CartDetailService.customCurrentCart(res.data) });
            } else {
              delete res.data?.Party;

              getCurrentCart = actionsCart.setCartFullfillmentFailed(CartDetailService.customCurrentCart(res.data), []);
            }

            return merge(
              of(getCurrentCart),
              of(getClientSOCart),
              from(get(config.GET_APPLIED)).pipe(
                map((res) => {
                  if (res.status === 200) {
                    return couponAction.getAppliedCouponListSuccess(deepCopy(res.data));
                  } else {
                    return couponAction.getAppliedCouponListFailed('Failed');
                  }
                })
              )
            );
          })
        );
      } else {
        return from(post(config.SET_FULLFILLMENT, action.payload.setFulfillmentPayload)).pipe(
          map((res) => {
            if (res.status === 200 && res.data?.Success) {
              return actions.getCurrentCartSuccess(CartDetailService.customCurrentCart(res.data), []);
            } else {
              delete res.data?.Party;

              return actionsCart.setCartFullfillmentFailed(CartDetailService.customCurrentCart(res.data), []);
            }
          })
        );
      }
    })
  );

export const setCartLineFullfillment = (action$) =>
  action$.pipe(
    ofType(cartTypes.SET_CART_LINE_FULLFILLMENT),
    switchMap((action) => {
      const clientSOCartId = getCartIdParam();
      let params = {
        ...action.payload.updateCartLineFulfillmentPayload
      };
      if (clientSOCartId) params.CartId = clientSOCartId;

      return postAsObservable(config.SET_CART_LINE_FULLFILLMENT, params).pipe(
        switchMap((res) => {
          if (res.status === 200 && res.data?.Success) {
            if (clientSOCartId) {
              return of(
                getClientSOCartSuccess({ OsimCart: CartDetailService.customCurrentCart(res.data) }),
                actions.turnUpdateAddressLoadingMaskOff()
              );
            }

            return of(actions.getCurrentCartSuccess(CartDetailService.customCurrentCart(res.data), []));
          } else {
            return of(actionsCart.setCartLineFullfillmentFailed(res?.data?.Errors?.[0] || 'Failed'));
          }
        }),
        catchError((error) => of(actionsCart.setCartLineFullfillmentFailed(error?.message || 'Failed'), actions.turnUpdateAddressLoadingMaskOff()))
      );
    })
  );

export const updateOneCartEpic = (action$) =>
  action$.pipe(
    ofType(cartTypes.UPDATE_ONE_CART_LINE),
    switchMap((action) => {
      const params = {
        Lines: [action.payload]
      };

      return postAsObservable(config.ACTION_UPDATE_CART, params).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actionsCart.updateOneCartLineSuccess();
          } else {
            return actionsCart.updateOneCartLineFailed(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(actionsCart.updateOneCartLineFailed(error.message || 'Failed')))
      );
    })
  );

export const getCartStripAdsEpic = (action$) =>
  action$.pipe(
    ofType(cartTypes.GET_CART_STRIP_ADS),
    switchMap(() => {
      return getAsObservable(config.ACTION_CART_STRIP_ADS).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            const dataSources = new CartStripAdsApiModel().getData(deepCopy(res.data));

            return actionsCart.getCartStripAdsSuccess(dataSources);
          } else {
            return actionsCart.getCartStripAdsFailed(res.data?.Errors?.[0] || 'Failed');
          }
        })
      );
    })
  );

export const getPickUpPointEpic = (action$) =>
  action$.pipe(
    ofType(cartTypes.PICK_UP_POINT),
    switchMap(() => {
      return getAsObservable(config.ACTION_PICK_UP_POINT).pipe(
        map((res) => {
          if (res.status === 200) {
            const dataSources = new PickUpPointsModel().getData(deepCopy(res.data));

            return actionsCart.getPickUpPointSuccess(dataSources?.PickUpPoints);
          } else {
            return actionsCart.getPickUpPointFailed(res.data?.Errors?.[0] || 'Failed');
          }
        })
      );
    })
  );

export const addStaffEpic = (action$) =>
  action$.pipe(
    ofType(cartTypes.ADD_STAFF),
    switchMap((action) => {
      return postAsObservable(config.ADD_STAFF, action.payload).pipe(
        map((res) => {
          if (res.status === 200) {
            return actionsCart.addStaffSuccess(res.data);
          } else {
            return actionsCart.addStaffFailed('Failed');
          }
        })
      );
    })
  );

export const getPromotionByIdEpic = (action$) =>
  action$.pipe(
    ofType(cartTypes.GET_PROMOTION_BY_ID),
    switchMap((action) => {
      return getAsObservable(config.GET_PROMOTION_BY_ID, action.payload).pipe(
        map((res) => {
          if (res.status === 200) {
            return actionsCart.getPromotionByIdSuccess(res.data);
          } else {
            return actionsCart.getPromotionByIdFailed('Failed');
          }
        })
      );
    })
  );

export const getCartDeliveryEpic = (action$) =>
  action$.pipe(
    ofType(cartTypes.GET_CART_DELIVERY),
    switchMap((action) => {
      const params = {
        deliveryOptionId: action.payload.deliveryOptionId || '',
        cartLineIds: action.payload.cartLineIds
      };

      return postAsObservable(config.GET_CART_DELIVERY, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getCurrentCartSuccess(CartDetailService.customCurrentCart(res.data), []);
          } else {
            return actionsCart.getCartDeliveryFailed('Failed');
          }
        })
      );
    })
  );

export const postBOTradeInFileEpic = (action$) =>
  action$.pipe(
    ofType(buyingOptionsTypes.UPLOAD_BO_TRADEIN_FILE),
    switchMap((action) => {
      let formData = new window.FormData();

      for (const [index, file] of Array.from(action.payload).entries()) {
        formData.append('file' + index, new window.File([file], file?.name));
      }

      return from(post(ACTION_UPLOAD_LIST_FILES, formData, { 'Content-Type': 'multipart/form-data' }, 'tradein')).pipe(
        map((res) => {
          if (res.status === 200) {
            let imageUploadedUrl = [];
            if (res.data?.length) {
              for (const item of res.data) {
                if (item?.Data?.Data) imageUploadedUrl.push(item.Data.Data);
              }
            }

            return buyingOptionsAction.uploadBOTradeInFileSuccess(imageUploadedUrl);
          } else {
            return buyingOptionsAction.uploadBOTradeInFileFailed(res);
          }
        })
      );
    })
  );

export const getGiftingOptionEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.GET_GIFTING_OPTION),
    switchMap(() => {
      return getAsObservable(config.GET_GIFTING_OPTION_LINK).pipe(
        map((res) => {
          return actions.getGiftingOptionSuccess(CartDetailService.customGiftingOptions(res.data));
        }),
        catchError((error) => of(actions.getGiftingOptionFailed(error.message || 'Failed')))
      );
    })
  );

export const removeGiftingOption = (action$) =>
  action$.pipe(
    ofType(productTypes.REMOVE_GIFTING_OPTION),
    switchMap((action) => {
      const params = {
        cartLineId: action.payload.cartLineId
      };

      return getAsObservable(config.REMOVE_GIFTING_OPTION_LINK, params).pipe(
        concatMap((res) => {
          let actionRemoveGiftingOption;
          if (res.status === 200 && res.data?.Success) {
            let filteredLineItem = [];

            const fullPath = window.location.href;

            const cartLineId = global.getQueryParams(fullPath, 'cartLineId') || null;

            const cartDetailApiModel = new CartDetailApiModel().getData(deepCopy(res.data));
            if (cartLineId) {
              const filterItem = cartDetailApiModel?.Lines?.filter((item) => item?.ExternalCartLineId === cartLineId)[0];

              filteredLineItem = filterItem ? [filterItem] : [];
            }

            actionRemoveGiftingOption = actions.getCurrentCartSuccess(CartDetailService.customCurrentCart(res.data), filteredLineItem);

            return merge(
              of(actionRemoveGiftingOption),
              from(get(config.GET_GIFTING_OPTION_LINK)).pipe(
                map((res) => {
                  if (res.status === 200) {
                    return actions.getGiftingOptionSuccess(CartDetailService.customGiftingOptions(res.data));
                  }
                })
              )
            );
          } else {
            return actions.getCurrentCartFailed({
              errorMessage: res.data?.Errors?.[0] || 'Failed',
              failureCode: res.data?.Data?.CommandStatuses || []
            });
          }
        }),
        catchError((error) => of(actions.getCurrentCartFailed(error.message || 'Failed')))
      );
    })
  );

export const updateCPCustomerEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.UPDATE_CP_CUSTOMER),
    switchMap((action) => {
      const params = {
        ...action.payload
      };

      return from(post(config.ACTION_UPDATE_CP_CUSTOMER_INTEREST, params)).pipe(
        map((res) => {
          if (res.status == 200) {
            getCPCustomer({
              id: SitecoreContextFactoryService.getValueContextItem('cpCustomerId'),
              fromURL: false
            });

            return actions.updateCPCustomerSuccess({
              ...res.data
            });
          } else {
            return actions.updateCPCustomerFailed(res.data?.Errors?.[0] || 'Failed');
          }
        })
      );
    }),
    catchError((error) => of(actions.updateCPCustomerFailed(error.message || 'Failed')))
  );

export const addToSOCartV2Epic = (action$) =>
  action$.pipe(
    ofType(productTypes.ADD_TO_SO_CART_V2),
    switchMap((action) => {
      const cartName = getCartNameValue();

      const params = {
        ...action.payload
      };
      if (cartName) params.cartName = cartName;

      return postAsObservable(config.ADD_TO_SO_CART_V2_ENDPOINT, params).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.addToSOCartSuccess(CartDetailService.customCurrentCart(res.data));
          } else {
            return actions.addToSOCartFailed(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(actions.addToSOCartFailed(error || 'Failed')))
      );
    })
  );