import Proptypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

import { getCartNameValue } from '../../../redux/epic/product/cartDetail/utils';
import BOShippingInfo from '../BOShippingInfo';
import AddToSalesOrderPopup from './AddToSalesOrderPopup';

const BOPaymentInfo = (props) => {
  const {
    dataFields,
    variantActive,
    totalSellingPriceText,
    totalListPriceText,
    deliveryOptions,
    productOverview,
    hasInstallmentPlan,
    isDeposit,
    installmentPeriods,
    totalSellingPrice,
    onClickAddHandler
  } = props;
  const priceExtraInformation = useSelector((state) => state.buyingOptionReducer.BORentalsReducer.priceExtraInformation);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);
  const dataBuyingOption = useSelector((state) => state?.buyingOptionReducer?.BOGetDataStatusReducer);
  const [installmentCartLevel, setInstallmentCartLevel] = useState(null);
  const [installmentProductLevel, setInstallmentProductLevel] = useState(null);
  const [installmentCartLevelAndProductLevel, setInstallmentCartLevelAndProductLevel] = useState(null);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const [isShow, setShow] = useState(false);
  const cpCustomerId = SitecoreContextFactoryService.getValueContextItem('cpCustomerId');
  const [currentCartName, setCurrentCartName] = useState('');

  useEffect(() => {
    const cartName = getCartNameValue();

    setCurrentCartName(cartName);
  }, []);

  const onShowHandler = () => {
    setShow(true);
  };

  const onCloseHandler = () => {
    setShow(false);
  };

  useEffect(() => {
    if (currentCart && variantActive) {
      setInstallmentCartLevel(
        currentCart?.PaymentConfiguration?.Gateways.length &&
        currentCart?.PaymentConfiguration?.Gateways.filter(
          (gateway) => gateway?.SupportInstallment && gateway?.InstallmentCartLevel && !gateway?.InstallmentProductLevel && gateway?.InstallmentPeriods.length
        )
      );

      setInstallmentProductLevel(
        currentCart?.PaymentConfiguration?.Gateways.length &&
        currentCart?.PaymentConfiguration?.Gateways.filter(
          (gateway) =>
            gateway?.SupportInstallment && gateway?.InstallmentProductLevel && !gateway?.InstallmentCartLevel && installmentPeriods?.length
        )
      );

      setInstallmentCartLevelAndProductLevel(
        currentCart?.PaymentConfiguration?.Gateways.length &&
        currentCart?.PaymentConfiguration?.Gateways.filter(
          (gateway) =>
            gateway?.SupportInstallment && gateway?.InstallmentProductLevel && gateway?.InstallmentCartLevel && installmentPeriods?.length
        )
      );
    }
  }, [currentCart, variantActive, totalSellingPrice]);

  const renderStatusLBL = (type) => {
    const statusLBLText = {
      InStock: 'LBL-InStock',
      PreOrderable: 'LBL-PreOrder',
      BackOrderable: 'LBL-BackOrder',
      OutOfStock: 'LBL-OutOfStock'
    };

    return statusLBLText[type];
  };

  return (
    variantActive && (
      <Fragment>
        <div className='bo-payment-info'>
          {/* <div className='bo-payment-info__wrap container'> */}
          <div className='bo-payment-info__wrap'>
            <div className='bo-payment-info__price'>
              {isDeposit ? (
                <Fragment>
                  <span className='bo-payment-info__price__current'>{dataBuyingOption?.Deposit?.DepositPriceWithCurrency}</span>
                </Fragment>
              ) : totalSellingPriceText === totalListPriceText ? (
                <Fragment>
                  <span className='bo-payment-info__price__current'>
                    {totalSellingPriceText} {priceExtraInformation}
                  </span>
                </Fragment>
              ) : (
                <Fragment>
                  <span className='bo-payment-info__price__current'>
                    {totalSellingPriceText} {priceExtraInformation}
                  </span>
                  <del className='bo-payment-info__price__old'>{totalListPriceText}</del>
                </Fragment>
              )}
            </div>
            {dataBuyingOption.BundleDealsButtonUrl ? (
              <a href={dataBuyingOption.BundleDealsButtonUrl} className='btn-link bo-payment-info__bundle__link'>
                <span>{messageObj?.['LBL_BundleDealsButtonUrl']}</span>
              </a>
            ) : (
              <></>
            )}
            {dataFields?.['Payment Gateways']?.Gateways?.map((gateway) => {
              return (
                <>
                  {(!isDeposit && !gateway?.InstallmentMaxCartAmount && totalSellingPrice >= gateway?.InstallmentMinCartAmount) ||
                  (!isDeposit &&
                    gateway?.InstallmentMaxCartAmount &&
                    totalSellingPrice >= gateway?.InstallmentMinCartAmount &&
                    totalSellingPrice < gateway?.InstallmentMaxCartAmount) ? (
                      <>
                        <span className='product-variant-info__content__installment' key={gateway?.Id}>
                          {gateway?.InstallmentProductLevelText.replace(
                            '{number_of_payments}',
                          // Global.onGetMinInstallmentPeriodsInPDPAndBO(installmentPeriods, gateway?.InstallmentPeriods)?.Period
                          gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period
                          )
                            .replace(
                              '${installment_amount}',
                              Math.round(
                              // (totalSellingPrice / Global.onGetMinInstallmentPeriodsInPDPAndBO(installmentPeriods, gateway?.InstallmentPeriods)?.Period) *
                                (totalSellingPrice / gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period) * 1000
                              ) / 1000
                            )
                            .replace('{logo}', '')}
                          {gateway?.InstallmentProductLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                            <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                          ) : (
                            <></>
                          )}
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  {!isDeposit && totalSellingPrice < gateway?.InstallmentMinCartAmount ? (
                    <span className='product-variant-info__content__installment' key={gateway?.Id}>
                      {gateway?.InstallmentCartLevelText.replace('${amount}', gateway?.InstallmentMinCartAmount - totalSellingPrice).replace(
                        '{logo}',
                        ''
                      )}
                      {gateway?.InstallmentCartLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                        <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                      ) : (
                        <></>
                      )}
                    </span>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
            {/*{installmentProductLevel?.map((gateway) => {*/}
            {/*  return (*/}
            {/*    <span className='product-variant-info__content__installment' key={gateway?.Id}>*/}
            {/*      {gateway?.InstallmentProductLevelText.replace(*/}
            {/*        '{number_of_payments}',*/}
            {/*        installmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]*/}
            {/*      )*/}
            {/*        .replace(*/}
            {/*          '${installment_amount}',*/}
            {/*          Math.round((totalSellingPrice / installmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]) * 1000) / 1000*/}
            {/*        )*/}
            {/*        .replace('{logo}', '')}*/}
            {/*      {gateway?.InstallmentProductLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (*/}
            {/*        <img className='installment-img' src={gateway?.PdpLogoItemUrl} />*/}
            {/*      ) : (*/}
            {/*        <></>*/}
            {/*      )}*/}
            {/*    </span>*/}
            {/*  );*/}
            {/*})}*/}
            {/*{installmentCartLevelAndProductLevel?.map((gateway) => {*/}
            {/*  return (*/}
            {/*    <>*/}
            {/*      {(!gateway?.InstallmentMaxCartAmount && totalSellingPrice >= gateway?.InstallmentMinCartAmount) || (gateway?.InstallmentMaxCartAmount && totalSellingPrice >= gateway?.InstallmentMinCartAmount && totalSellingPrice < gateway?.InstallmentMaxCartAmount) ? (*/}
            {/*        <span className='product-variant-info__content__installment' key={gateway?.Id}>*/}
            {/*          {gateway?.InstallmentProductLevelText.replace(*/}
            {/*            '{number_of_payments}',*/}
            {/*            installmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]*/}
            {/*          )*/}
            {/*            .replace(*/}
            {/*              '${installment_amount}',*/}
            {/*              Math.round((totalSellingPrice / installmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]) * 1000) / 1000*/}
            {/*            )*/}
            {/*            .replace('{logo}', '')}*/}
            {/*          {gateway?.InstallmentProductLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (*/}
            {/*            <img className='installment-img' src={gateway?.PdpLogoItemUrl} />*/}
            {/*          ) : (*/}
            {/*            <></>*/}
            {/*          )}*/}
            {/*        </span>*/}
            {/*      ) : (*/}
            {/*        <></>*/}
            {/*      )}*/}
            {/*      {totalSellingPrice < gateway?.InstallmentMinCartAmount ? (*/}
            {/*        <span className='product-variant-info__content__installment' key={gateway?.Id}>*/}
            {/*          {gateway?.InstallmentCartLevelText.replace('${amount}', gateway?.InstallmentMinCartAmount - totalSellingPrice).replace(*/}
            {/*            '{logo}',*/}
            {/*            ''*/}
            {/*          )}*/}
            {/*          {gateway?.InstallmentCartLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (*/}
            {/*            <img className='installment-img' src={gateway?.PdpLogoItemUrl} />*/}
            {/*          ) : (*/}
            {/*            <></>*/}
            {/*          )}*/}
            {/*        </span>*/}
            {/*      ) : (*/}
            {/*        <></>*/}
            {/*      )}*/}
            {/*    </>*/}
            {/*  );*/}
            {/*})}*/}
            {/*<RichText className='bo-payment-info__calculation' field={productOverview} tag='p' />*/}
            {isClientTelingUser ? (
              <div className='bo-payment-info__btn'>
                <div className='bo-payment-info__btn__item'>
                  <button onClick={cpCustomerId || currentCartName ? onClickAddHandler : onShowHandler} className='btn btn-outline-primary item-btn'>
                    <Text field={dataFields['Add To Sales Order Text']} />
                  </button>
                  {/*<Link*/}
                  {/*  field={{ href: `${dataFields['Purchase On Spot Link']?.value?.href}?transactionAmount=${totalSellingPriceText}` }}*/}
                  {/*  className='btn btn-primary item-btn'*/}
                  {/*>*/}
                  {/*  <Text field={dataFields['Purchase On Spot Text']} />*/}
                  {/*</Link>*/}
                </div>
                <div className='bo-payment-info__btn__link'>
                  <Link field={dataFields['CTA Button']} className='btn-link'>
                    <Text field={dataFields['CTA Button Label']} />
                  </Link>
                </div>
                <AddToSalesOrderPopup isShow={isShow} onCloseHandler={onCloseHandler} ondataSources={dataFields} />
              </div>
            ) : (
              <div className='bo-payment-info__fulfillment'>
                <h3 className='bo-payment-info__fulfillment__title bo-title-section'>{dataFields['Fulfillment Details Label'].value}</h3>
                <BOShippingInfo
                  dataTextFields={dataFields}
                  statusLBL={messageObj?.[renderStatusLBL(variantActive.Stock.StockStatus.Name)] || ''}
                  deliveryStatus={variantActive.Stock.FinalStatus.DeliveryLabel}
                  storePickUpLabel={dataFields['Store Pick Up Label'].value}
                  deliveryOptions={deliveryOptions}
                />
              </div>
            )}
          </div>
        </div>
      </Fragment>
    )
  );
};

BOPaymentInfo.propTypes = {
  dataFields: Proptypes.any,
  children: Proptypes.any,
  isDeposit: Proptypes.any,
  productOverview: Proptypes.any,
  variantActive: Proptypes.any,
  totalSellingPriceText: Proptypes.any,
  totalListPriceText: Proptypes.any,
  deliveryOptions: Proptypes.array,
  hasInstallmentPlan: Proptypes.bool,
  installmentPeriods: Proptypes.array,
  totalSellingPrice: Proptypes.number
};

export default BOPaymentInfo;
