import './PaymentMethodComponent.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { downloadInvoice } from '@redux/actions/checkoutProcess';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

const DOWNLOAD_INVOICE_ENDPOINT = '/api/osim/OrderReport/DownloadOrder';
const MONTH_TEXT = '{Month}';

const PaymentMethodComponent = ({ layoutData, cartData, orderId, currentCart }) => {
  const currentCartBalance = useSelector((state) => state?.myOrderReducer?.balanceByIdReducer?.currentCartCustom);
  const dispatch = useDispatch();
  const [downloadLink, setDownloadLink] = useState('');
  const [fullPayment, setFullPayment] = useState(null);
  const isDeposit = useSelector((state) => state?.myOrderReducer?.balanceByIdReducer?.currentCartCustom?.IsDeposit);

  useEffect(() => {
    const linkDownload = `${window.location.origin}${DOWNLOAD_INVOICE_ENDPOINT}?orderId=${orderId}&timeOffset=${new Date().getTimezoneOffset()}`;

    setDownloadLink(linkDownload);
  }, []);

  useEffect(() => {
    if (currentCart.IsInstallment) {
      let temp = deepCopy(layoutData['Pay In Installment Label']);

      currentCart?.CardType
        ? (temp.value = `${temp.value} ${currentCart?.PaymentMethodName} - ${currentCart?.CardType}`)
        : (temp.value = `${temp.value} ${currentCart?.PaymentMethodName}`);

      setFullPayment(deepCopy(temp));
    } else {
      let temp = deepCopy(layoutData['Pay In Full Label']);

      currentCart?.CardType
        ? (temp.value = `${temp.value} ${currentCart?.PaymentMethodName} - ${currentCart?.CardType}`)
        : (temp.value = `${temp.value} ${currentCart?.PaymentMethodName}`);

      setFullPayment(deepCopy(temp));
    }
  }, []);

  const download = () => {
    dispatch(downloadInvoice(orderId));
  };

  const paymentLabel = useMemo(() => {
    let paymentLabelText = layoutData?.['Pay In Full Label'];
    if (currentCart.IsInstallment) paymentLabelText = layoutData?.['Pay In Installment Label'];

    return paymentLabelText;
  }, [currentCart, layoutData]);

  const paymentMethodText = useMemo(() => {
    const paymentsList = currentCart?.Payments || [];
    let paymentMethodString = '';
    if (paymentsList.length)
      paymentMethodString = paymentsList
        .map((paymentItem) => (paymentItem.PaymentMethodName || '') + (paymentItem.CardType ? ` - ${paymentItem.CardType}` : ''))
        .join(', ');

    return paymentMethodString;
  }, [currentCart]);

  return (
    cartData &&
    fullPayment &&
    (currentCartBalance && currentCartBalance?.DepositListProduct.length > 0 ? (
      <></>
    ) : (
      <div className='payment-method-order'>
        <Text className='payment-method-order__title' tag='h4' field={layoutData['Payment Method Label']} />
        {/* <Text field={fullPayment} className='payment-method-order__detail' tag='p' /> */}
        <p className='payment-method-order__detail'>
          <Text field={paymentLabel} /> {paymentMethodText}
        </p>
        <div className='payment-method-order__invoice'>
          <a className='payment-method-order__invoice__link' href={downloadLink}>
            <Text field={layoutData['Download Invoice Label']} tag='span' />
          </a>
          {/* <span onClick={() => download()}>Download Invoice</span> */}
        </div>
      </div>
    ))
  );
};

PaymentMethodComponent.propTypes = {
  layoutData: PropTypes.any,
  cartData: PropTypes.any,
  orderId: PropTypes.string,
  currentCart: PropTypes.any
};

export default PaymentMethodComponent;
