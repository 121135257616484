import Proptypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { isStickyHeader$ } from '@components/Product/VariantDetail/ProductDetailHeader/handleStickyHeaderService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import CPPageHeader from '../../ClientPortal/CPPageHeader';

const BOHeader = (props) => {
  const { productName, totalSellingPriceText, totalListPriceText, isActivePrice } = props;
  const [isBOHeaderSticky, setIsBOHeaderSticky] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const priceExtraInformation = useSelector((state) => state.buyingOptionReducer.BORentalsReducer.priceExtraInformation);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const isDeposit = useSelector((state)=> state?.buyingOptionReducer?.BOGetDataStatusReducer?.IsDeposit);
  const dataDeposit = useSelector((state)=> state?.buyingOptionReducer?.BOGetDataStatusReducer?.Deposit);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  useEffect(() => {
    if (scrollTop > 0) {
      setIsBOHeaderSticky(true);

      isStickyHeader$.next(true);
    } else {
      setIsBOHeaderSticky(false);

      isStickyHeader$.next(false);
    }
  }, [scrollTop]);

  return (
    <Fragment>
      {isClientTelingUser ? (
        <CPPageHeader title={SitecoreContextFactoryService.getValueContextRouteItem('displayName')} />
      ) : (
        <div className={`buying-option-header${isBOHeaderSticky ? ' buying-option-header-sticky' : ''}`}>
          <div className='container buying-option-header__wrap'>
            <div className='buying-option-header__text'>
              <span className='buying-option-header__text__name' title={`${productName}`}>
                {productName}
              </span>
              <div className='buying-option-header__text__price'>
                {isDeposit ? (
                  <>
                    <Text field={props?.dataFields?.['Deposit Label']} className='buying-option-header__text__price__old' tag='span'/>
                    <span className={'buying-option-header__text__price__current' + `${isActivePrice ? '' : ' disable'}`}>
                      {dataDeposit?.DepositPriceWithCurrency}
                    </span>
                  </>
                ) : (
                  totalSellingPriceText !== '0' && totalListPriceText !== '0' && (
                    totalSellingPriceText === totalListPriceText ? (
                      <span className={'buying-option-header__text__price__current' + `${isActivePrice ? '' : ' disable'}`}>
                        {totalSellingPriceText} {priceExtraInformation}
                      </span>
                    ) : (
                      <Fragment>
                        <span className={'buying-option-header__text__price__current' + `${isActivePrice ? '' : ' disable'}`}>
                          {totalSellingPriceText} {priceExtraInformation}
                        </span>
                        <del className='buying-option-header__text__price__old'>{totalListPriceText}</del>
                      </Fragment>
                    )
                  )
                )}
              </div>
            </div>
            {props.children}
          </div>
        </div>
      )}
    </Fragment>
  );
};

BOHeader.propTypes = {
  productName: Proptypes.any,
  children: Proptypes.any,
  totalSellingPriceText: Proptypes.any,
  totalListPriceText: Proptypes.any,
  CurrencyCode: Proptypes.any
};

export default BOHeader;
