export const MILLISECOND_PER_DAY = 86400000;
export const ALLSTOREID = 'AllStores';

export const EXPIRYDATE = 'expiryDate';
export const DATE = 'date';
export const SALESORDERVALUE = 'salesOrderValue';
export const SALESTAFF = 'saleStaff';
export const STORE = 'store';
export const STATUS = 'status';
export const CATEGORY = 'category';

