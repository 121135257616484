import './CustomeInfoPopup.scss';

import PropTypes, { object } from 'prop-types';
import React, { Fragment, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import CPDOB from '@components/ClientPortal/CPDOB';
import CPSelect from '@components/ClientPortal/GenericComponents/CPForm/CPSelect';
import Loading from '@components/Share/Loading';
import { setIsActiveQACustomerLifestyle, storeCPCustomer, updateCPCustomerContact } from '@redux/actions/clientTellingPortalCustomer';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { DEFAULT_API_VALUE } from '@utils/constant';
import { HK_SITE_NAME, TW_SITE_NAME } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useFormatEnDate } from '@utils/customsHook/useFormatDate';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import { convertHKTWDate } from '../../../../utils/customsHook/useFormatDate/utils';
import CPInput from '../../GenericComponents/CPForm/CPInput';
import { TYPE_CHECKBOX, TYPE_RADIO, TYPE_TEL, TYPE_TEXT } from '../../GenericComponents/CPForm/FormConstant';
import QuickAccessPopup from '../QuickAccessPopup';
import ExistingCustomerLifestyle from './ExistingCustomerLifestyle';
import { getAnswerSelectedDisplayName } from './utils';

const CustomeInfoPopup = ({ closeAccessPopup, layoutData, dataModel }) => {
  const [editCustomerLifestyle, isEditCustomerLifestyle] = useState(false);
  const [dirtyMassageOwnerShip, setDirtyMassageOwnnerShip] = useState(false);
  const [selectedMassageOwnerShip, setSelectedMassageOwnerShip] = useState([]);
  const [massageOwnerShip, setMassageOwnerShip] = useState(false);
  const [massageOwnershipValue, setMassageOwnershipValue] = useState(' ');

  const clientTellingPortalQuickAccess = useSelector((state) => state.clientTellingPortalQuickAccess);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const dirtyCustomerLifestyle = useSelector((state) => state.singleSignOnReducer.userInfoReducer.dirtyCustomerLifestyle);

  const dispatch = useDispatch();
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const currentLanguage = SitecoreContextFactoryService.getValueContextItem('language');
  const formatEnDate = useFormatEnDate();

  const isConvertDate = useMemo(() => {
    return (currentSiteName === HK_SITE_NAME && currentLanguage === 'zh-HK') || currentSiteName === TW_SITE_NAME;
  }, [currentSiteName, currentLanguage]);

  const generateDropdownOption = (data) => {
    let arr = data.map((item) => {
      return { value: item.fields.Answer.value, label: item.fields.Answer.value };
    });

    return arr;
  };

  const onChangeSelect = (data) => {
    if (data.questionType == 'CPCustomerInfo-Single Select' && data.questionCode == 'MassageOwnershipType') {
      setDirtyMassageOwnnerShip(true);

      data.displayName?.toLowerCase() === 'no' ? setMassageOwnerShip(false) : setMassageOwnerShip(true);

      setSelectedMassageOwnerShip([]);
    }

    dispatch(
      storeCPCustomer({
        key: data.questionCode,
        value: data.value,
        type: data.questionType,
        answerId: data.answerId
      })
    );
  };

  const onSaveCustomerInformation = () => {
    dispatch(setIsActiveQACustomerLifestyle(false));

    let formData = new window.FormData();

    const params = {
      ContactID: clientTellingPortalCustomer.tmpCustomerInformation?.ContactID,
      // Email: ' ',
      // PhoneNumber: ' ',
      // PrefixPhoneNumber: clientTellingPortalCustomer.tmpCustomerInformation?.PrefixPhoneNumber || ' ',
      // DOB: ' ',
      PersonalInformation: {
        // FirstName: clientTellingPortalCustomer.tmpCustomerInformation?.PersonalInformation?.FirstName || ' ',
        // LastName: clientTellingPortalCustomer.tmpCustomerInformation?.PersonalInformation?.LastName || ' ',
        Gender: clientTellingPortalCustomer.tmpCustomerInformation?.PersonalInformation?.Gender || DEFAULT_API_VALUE
      },
      CPCustomerInfo: {
        MaritalStatus: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MaritalStatus || DEFAULT_API_VALUE,
        MaritalStatusId: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MaritalStatusId || DEFAULT_API_VALUE,
        // Age: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.Age || ' ',
        JobNature: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.JobNature || DEFAULT_API_VALUE,
        JobNatureId: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.JobNatureId || DEFAULT_API_VALUE,
        HealthIssues: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.HealthIssues || DEFAULT_API_VALUE,
        HealthIssuesId: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.HealthIssuesId || DEFAULT_API_VALUE,
        HouseholdSize:
          clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.HouseholdSize ||
          layoutData.Questions.find((item) => item['Question Code'] == 'HouseholdSize')?.Answers?.[0]?.fields?.Answer?.value,
        HouseholdSizeId:
          clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.HouseholdSizeId ||
          layoutData.Questions.find((item) => item['Question Code'] == 'HouseholdSize')?.Answers?.[0]?.id,
        MassageOwnershipType: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnershipType || DEFAULT_API_VALUE,
        MassageOwnershipTypeId: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnershipTypeId || DEFAULT_API_VALUE,
        MassageOwnership: massageOwnershipValue || DEFAULT_API_VALUE,
        MassageOwnershipId: massageOwnershipValue.length ? clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnershipId || DEFAULT_API_VALUE : DEFAULT_API_VALUE,
        // AllowDirectMail: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.AllowDirectMail || false,
        // AllowSms: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.AllowSms || false,
        // AllowEmail: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.AllowEmail || false,
        // PDPAAgreement: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.PDPAAgreement || false,
        ReferPOS: clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.ReferPOS || DEFAULT_API_VALUE,
        GenderId:
          clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.GenderId ||
          clientTellingPortalCustomer.tmpCustomerInformation?.PersonalInformation?.GenderId ||
          DEFAULT_API_VALUE
      },
      // CPCustomerInterest: {
      //   Products: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInterest?.Products || ' '
      // }
    };

    for (let keyParams in params) {
      if (keyParams == 'PersonalInformation') {
        for (let keyChild in params[keyParams]) {
          formData.append(`PersonalInformation[${keyChild}]`, params[keyParams][keyChild]);
        }
      } else if (keyParams == 'CPCustomerInfo') {
        for (let keyChild in params[keyParams]) {
          formData.append(`CPCustomerInfo[${keyChild}]`, params[keyParams][keyChild]);
        }
      } else if (keyParams == 'CPCustomerInterest') {
        for (let keyChild in params[keyParams]) {
          params[keyParams][keyChild].forEach((item, index) => {
            for (let keyItem in item) {
              if (keyItem == 'Questions') {
                params[keyParams][keyChild][index][keyItem].forEach((childItem, childIndex) => {
                  for (let keyChildItem in childItem) {
                    formData.append(`CPCustomerInterest[${keyChild}][${index}][${keyItem}][${childIndex}][${keyChildItem}]`, childItem[keyChildItem]);
                  }
                });
              } else formData.append(`CPCustomerInterest[${keyChild}][${index}][${keyItem}]`, item[keyItem]);
            }
          });
        }
      } else {
        formData.append(keyParams, params[keyParams]);
      }
    }

    dispatch(updateCPCustomerContact(formData));
  };

  const onSelectMassageOwnership = (option) => {
    setSelectedMassageOwnerShip(option);

    setMassageOwnershipValue(option?.map((item) => item.value).join(', ') || '');

    const selectedMultiOptionConvert = Global.onGetSelectedMultiOption(option || [], selectedMassageOwnerShip);

    dispatch(
      storeCPCustomer({
        key: 'MassageOwnership',
        value: selectedMultiOptionConvert?.value,
        type: 'CPCustomerInfo-Multi Select',
        answerId: selectedMultiOptionConvert?.answerId
      })
    );
  };

  useDidUpdateEffect(() => {
    const answerValueSelected = clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnershipType || '';

    const answerValueSelectedDisplayName = getAnswerSelectedDisplayName({
      answerValueSelected,
      questionsList: layoutData?.Questions || []
    });

    if (answerValueSelectedDisplayName === 'no') {
      setMassageOwnerShip(false);

      setDirtyMassageOwnnerShip(true);
    } else if (answerValueSelected.length) {
      setMassageOwnerShip(true);

      setDirtyMassageOwnnerShip(true);

      const massageOwnershipValue = clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnership;
      if (massageOwnershipValue && massageOwnershipValue.length) {
        const splitMassageOwnerShipValue = clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnership?.split(', ');
        if (splitMassageOwnerShipValue.length) {
          let selectedMassageOwnerShipTemp = [];
          for (const [index, item] of splitMassageOwnerShipValue.filter((item) => item !== ' ').entries()) {
            selectedMassageOwnerShipTemp.push({
              value: item,
              label: item,
              answerId: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnershipId?.split(', ')?.[index] || ''
            });
          }

          setSelectedMassageOwnerShip(selectedMassageOwnerShipTemp);
        }
      }
    }
  }, [clientTellingPortalCustomer]);

  const onRemoveMassageOwnerTypeBtnClick = (item) => {
    let tempObj = deepCopy(selectedMassageOwnerShip);

    const indexOfItem = tempObj.findIndex((val) => val.value === item.value);

    tempObj.splice(indexOfItem, 1);

    setSelectedMassageOwnerShip(tempObj);

    setMassageOwnershipValue(tempObj?.map((item) => item.value).join(', ') || ' ');

    dispatch(
      storeCPCustomer({
        key: 'MassageOwnership',
        value: tempObj.map((item) => item.value).join(', ') || ' ',
        type: 'CPCustomerInfo-Multi RemoveMassageOwner'
      })
    );
  };

  const dobText = useMemo(() => {
    let value = 'N/A';
    const rawDob = clientTellingPortalCustomer?.tmpCustomerInformation?.DOB;
    if (rawDob) {
      value = isConvertDate
        ? convertHKTWDate({
          dateString: rawDob,
          isHideYear: true
        })
        : formatEnDate({
          date: rawDob,
          isHideYear: true
        });
    }

    return value;
  }, [clientTellingPortalCustomer, isConvertDate]);

  const selectedOwnerTypeValuesList = useMemo(
    () => [...selectedMassageOwnerShip].sort((a, b) => a.label - b.label).reverse(),
    [selectedMassageOwnerShip]
  );

  return (
    <QuickAccessPopup
      closeAccessPopup={closeAccessPopup}
      isEditable={
        (dirtyCustomerLifestyle && !editCustomerLifestyle) ||
        (clientTellingPortalCustomer?.contacts[0]?.contact_type !== 'Anonymous lead' && !dirtyCustomerLifestyle)
          ? true
          : false
      }
      isCenter={false}
      title={<h2 className='qA-popup__title'>{layoutData?.['Pop Up Title'].value}</h2>}
      onEditCustomerLifestyle={() => isEditCustomerLifestyle(true)}
      editButtonText={dataModel?.['Edit Button Label']?.value}
    >
      {clientTellingPortalCustomer.isLoading ? (
        <Loading />
      ) : (
        <>
          {/* {clientTellingPortalCustomer.contacts?.[0]?.contact_type == 'PDPA lead' || Object.keys(clientTellingPortalCustomer.customerInformation?.CPCustomerInfo).length ? ( */}
          {dirtyCustomerLifestyle || clientTellingPortalCustomer?.contacts[0]?.contact_type !== 'Anonymous lead' ? (
            <ExistingCustomerLifestyle
              layoutData={layoutData}
              data={clientTellingPortalCustomer.customerInformation}
              editCustomerLifestyle={editCustomerLifestyle}
              onEditCustomerLifestyle={() => isEditCustomerLifestyle(false)}
            />
          ) : (
            <div className='customer-info'>
              <div className='customer-info__form cp-form'>
                <div className='cp-form__wrap'>
                  <div className='cp-form__left'>
                    <div className='form-group'>
                      <CPInput
                        inputType={TYPE_TEXT}
                        isDisabled={true}
                        inputPlacehoder={layoutData['First Name Label'].value}
                        inputValue={clientTellingPortalCustomer.customerInformation?.PersonalInformation?.FirstName}
                      />
                    </div>
                    <div className='form-group d-flex'>
                      <div className='cp-form__email'>
                        <CPInput
                          inputType={TYPE_TEXT}
                          isDisabled={true}
                          inputPlacehoder={layoutData['Email Label'].value}
                          inputValue={clientTellingPortalCustomer.customerInformation?.Email}
                        />
                      </div>
                      <div className='cp-form__radio-right'>
                        <div className='osim-radio disable'>
                          <input disabled className='osim-radio-input' type='radio' id='customer-info-radio-1' />
                          <label className='osim-radio-label' htmlFor='customer-info-radio-1'></label>
                        </div>
                      </div>
                    </div>
                    <div className='form-group form-group--mb-30 form-group-custom__space'>
                      {/*<a href='#' className='cp-form__add-new disabled'>*/}
                      {/*  Add New*/}
                      {/*</a>*/}
                    </div>
                    <div className='form-group d-flex'>
                      <div className='cp-form__icon-left'>
                        <Image field={layoutData['Birthday Icon']} />
                      </div>
                      <div className='cp-form__group select-small'>
                        {clientTellingPortalCustomer.tmpCustomerInformation?.DOB?.length ? <span>{dobText}</span> : <CPDOB disabled={true} />}
                      </div>
                    </div>
                    {layoutData.Questions.map((q, iq) => {
                      return (
                        <Fragment key={iq}>
                          {iq % 2 == 0 ? (
                            <>
                              <div className='form-group d-flex'>
                                <div className='cp-form__icon-left'>
                                  <Image field={q.Image} />
                                </div>
                                <div className='cp-form__group'>
                                  {q['Display Type'] == 'Drop Down' ? (
                                    <CPSelect
                                      selectId='selectId'
                                      onChangeSelect={onChangeSelect}
                                      options={generateDropdownOption(q.Answers)}
                                      selectPlaceholder={q?.['Question'] || 'Select HouseholdSize'}
                                      questionCode={q['Question Code']}
                                      questionType={q['Display Type']}
                                      isResetProductSelection={
                                        clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.HouseholdSize
                                          ? null
                                          : 'QuickAccessPrefixPhoneNumber'
                                      }
                                      isDisabled={
                                        q?.['POS Related'] == '1' && clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.ReferPOS
                                          ? true
                                          : false
                                      }
                                    />
                                  ) : (
                                    <>
                                      {q.Answers.map((a, ia) => {
                                        return (
                                          <div className='cp-form__group__item' key={ia}>
                                            {/* <div
                                            className={`cp-input ${
                                              q['Display Type'] == 'Single Select' || q['Display Type'] == 'Multi Select' ? 'type-select' : ''
                                            } --rectangle`}
                                          >
                                            <input
                                              type={
                                                q['Display Type'] == 'Single Select'
                                                  ? TYPE_RADIO
                                                  : q['Display Type'] == 'Multi Select' && TYPE_CHECKBOX
                                              }
                                              id={a.id}
                                              name={q['Question Code']}
                                            />
                                            <label htmlFor={a.id} className='cp-input__label'>
                                              <Text field={a.fields.Answer} />
                                            </label>
                                          </div> */}
                                            <CPInput
                                              inputName={q['Display Type'] == 'Single Select' ? `input-radio-${q.Question}` : `input-radio-${a.id}`}
                                              isRectangle={true}
                                              inputType={
                                                q['Display Type'] == 'Single Select'
                                                  ? TYPE_RADIO
                                                  : q['Display Type'] == 'Multi Select' && TYPE_CHECKBOX
                                              }
                                              inputId={`input-radio-${a.id}`}
                                              labelData={a.fields.Answer.value}
                                              layoutServiceData={a.fields}
                                              inputChange={onChangeSelect}
                                              questionCode={q['Question Code']}
                                              questionType={q['Display Type']}
                                              isChecked={
                                                q['Question Code'] == 'Gender'
                                                  ? clientTellingPortalCustomer.tmpCustomerInformation?.PersonalInformation?.[`${q['Question Code']}`]
                                                    ?.split(', ')
                                                    .includes(a.fields.Answer.value)
                                                    ? true
                                                    : false
                                                  : clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.[q['Question Code']]
                                                    ?.split(', ')
                                                    .includes(a.fields.Answer.value)
                                                    ? true
                                                    : false
                                              }
                                              isDisabled={
                                                q?.['POS Related'] == '1' && clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.ReferPOS
                                                  ? true
                                                  : false
                                              }
                                              displayName={a?.displayName}
                                              answerId={a?.id}
                                            />
                                          </div>
                                        );
                                      })}
                                    </>
                                  )}
                                </div>
                              </div>
                              {q['Question Code'] == 'MassageOwnershipType' && dirtyMassageOwnerShip ? (
                                <div className='form-group d-flex'>
                                  <div className='cp-form__icon-left'></div>
                                  <div className='cp-form__group'>
                                    <Select
                                      options={layoutData['Massage Ownership Types'].map((item) => {
                                        return {
                                          value: item.fields.Value.value,
                                          label: item.fields.Value.value,
                                          answerId: item.id
                                        };
                                      })}
                                      className='cp-select cp-select__customize'
                                      classNamePrefix='cp-select'
                                      value={selectedOwnerTypeValuesList}
                                      onChange={onSelectMassageOwnership}
                                      isSearchable={true}
                                      isMulti
                                      isDisabled={massageOwnerShip ? false : true}
                                    />
                                    <div className='cp-select__customize-selected__item'>
                                      {selectedOwnerTypeValuesList.length > 0 &&
                                        selectedOwnerTypeValuesList.map((val, index) => (
                                          <div key={index} className='item'>
                                            <p className='item-text'>{val.label}</p>
                                            <div className='item-remove__icon icon-close' onClick={() => onRemoveMassageOwnerTypeBtnClick(val)}></div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <Fragment key={iq} />
                          )}
                        </Fragment>
                      );
                    })}
                    {/* <div className='form-group d-flex'>
                            <div className='cp-form__icon-left'></div>
                            <div className='cp-form__group'>
                              <CPSelect selectId='selectId' onChangeSelect={onChangeSelect()} options={options} selectPlaceholder='Massage chair' />
                            </div>
                          </div> */}
                  </div>
                  <div className='cp-form__right'>
                    <div className='form-group'>
                      <CPInput
                        inputType={TYPE_TEXT}
                        isDisabled={true}
                        inputPlacehoder='Last Name'
                        inputValue={clientTellingPortalCustomer.customerInformation?.PersonalInformation?.LastName}
                      />
                    </div>
                    <div className='form-group d-flex'>
                      <div className='cp-form__phone'>
                        <div className='cp-form__phone__code select-small'>
                          <Select
                            options={layoutData['Phone Country Code'].map((item) => {
                              return {
                                value: item.fields.Code.value,
                                label: item.fields.Code.value
                              };
                            })}
                            className='cp-select'
                            classNamePrefix='cp-select'
                            isSearchable={false}
                            value={
                              clientTellingPortalCustomer.customerInformation?.PrefixPhoneNumber
                                ? {
                                  value: clientTellingPortalCustomer.customerInformation?.PrefixPhoneNumber,
                                  label: clientTellingPortalCustomer.customerInformation?.PrefixPhoneNumber
                                }
                                : layoutData['Phone Country Code'].map((item) => {
                                  return {
                                    value: item.fields.Code.value,
                                    label: item.fields.Code.value
                                  };
                                })[0]
                            }
                            isDisabled={true}
                          />
                        </div>
                        <div className='cp-form__phone__number'>
                          <div className='cp-input'>
                            <input
                              type={TYPE_TEL}
                              id='PDPA-phoneNumber'
                              name='PDPA-phoneNumber'
                              placeholder={layoutData['Phone Number Label'].value}
                              disabled
                              value={clientTellingPortalCustomer.customerInformation?.PhoneNumber}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='cp-form__radio-right'>
                        <div className='osim-radio disable'>
                          <input disabled className='osim-radio-input' type='radio' id='customer-info-radio-1' />
                          <label className='osim-radio-label' htmlFor='customer-info-radio-1'></label>
                        </div>
                      </div>
                    </div>
                    <div className='form-group form-group--mb-30 d-flex d-flex--space-between '>
                      <ul className='cp-form__social-icon'>
                        <li className='cp-form__social-icon__item cp-checkbox-icon'>
                          <input disabled type='checkbox' className='cp-checkbox-icon__input' id='lifeStyleMessageButton' />
                          <label className='cp-checkbox-icon__icon' htmlFor='lifeStyleMessageButton'>
                            <img src={require('@assets/images/CP-images/icon-wechat.svg')} alt='icon' />
                            {/* <Image field={layoutData['Message Button Icon']} /> */}
                          </label>
                        </li>
                        <li className='cp-form__social-icon__item cp-checkbox-icon'>
                          <input disabled type='checkbox' className='cp-checkbox-icon__input' id='lifeStyleDialButton' />
                          <label className='cp-checkbox-icon__icon' htmlFor='lifeStyleDialButton'>
                            <img src={require('@assets/images/CP-images/icon-whatsapp.svg')} alt='icon' />
                            {/* <Image field={layoutData['Dial Button Icon']} /> */}
                          </label>
                        </li>
                        <li className='cp-form__social-icon__item cp-checkbox-icon'>
                          <input disabled type='checkbox' className='cp-checkbox-icon__input' id='lifeStyleSMSIcon' />
                          <label className='cp-checkbox-icon__icon' htmlFor='lifeStyleSMSIcon'>
                            <img src={require('@assets/images/CP-images/icon-sms.png')} alt='icon' />
                            {/* <Image field={layoutData['SMS Icon']} /> */}
                          </label>
                        </li>
                      </ul>
                      {/* <a href='' className='cp-form__add-new'>
                              Add New
                            </a> */}
                    </div>
                    {layoutData.Questions.map((q, iq) => {
                      return (
                        <Fragment key={iq}>
                          {iq % 2 != 0 ? (
                            <>
                              <div className='form-group d-flex'>
                                <div className='cp-form__icon-left'>
                                  <Image field={q.Image} />
                                </div>
                                <div className='cp-form__group'>
                                  {q['Display Type'] == 'Drop Down' ? (
                                    <CPSelect
                                      selectId='selectId'
                                      onChangeSelect={onChangeSelect}
                                      options={generateDropdownOption(q.Answers)}
                                      selectPlaceholder={q?.['Question'] || 'Select HouseholdSize'}
                                      questionCode={q['Question Code']}
                                      questionType={q['Display Type']}
                                      isResetProductSelection={
                                        clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.HouseholdSize
                                          ? null
                                          : 'QuickAccessPrefixPhoneNumber'
                                      }
                                      isDisabled={
                                        q?.['POS Related'] == '1' && clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.ReferPOS
                                          ? true
                                          : false
                                      }
                                    />
                                  ) : (
                                    <>
                                      {q.Answers.map((a, ia) => {
                                        return (
                                          <div className='cp-form__group__item' key={ia}>
                                            {/* <div
                                            className={`cp-input ${
                                              q['Display Type'] == 'Single Select' || q['Display Type'] == 'Multi Select' ? 'type-select' : ''
                                            } --rectangle`}
                                          >
                                            <input
                                              type={
                                                q['Display Type'] == 'Single Select'
                                                  ? TYPE_RADIO
                                                  : q['Display Type'] == 'Multi Select' && TYPE_CHECKBOX
                                              }
                                              id={a.id}
                                              name={q['Question Code']}
                                            />
                                            <label htmlFor={a.id} className='cp-input__label'>
                                              <Text field={a.fields.Answer} />
                                            </label>
                                          </div> */}
                                            <CPInput
                                              inputName={q['Display Type'] == 'Single Select' ? `input-radio-${q.Question}` : `input-radio-${a.id}`}
                                              isRectangle={true}
                                              inputType={
                                                q['Display Type'] == 'Single Select'
                                                  ? TYPE_RADIO
                                                  : q['Display Type'] == 'Multi Select' && TYPE_CHECKBOX
                                              }
                                              inputId={`input-radio-${a.id}`}
                                              labelData={a.fields.Answer.value}
                                              layoutServiceData={a.fields}
                                              inputChange={onChangeSelect}
                                              questionCode={q['Question Code']}
                                              questionType={q['Display Type']}
                                              isChecked={
                                                q['Question Code'] == 'Gender'
                                                  ? clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.[`${q['Question Code']}Id`]
                                                    ?.split(', ')
                                                    .includes(a.id)
                                                    ? true
                                                    : false
                                                  : clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.[`${q['Question Code']}Id`]
                                                    ?.split(', ')
                                                    .includes(a.id)
                                                    ? true
                                                    : false
                                              }
                                              isDisabled={
                                                q?.['POS Related'] == '1' && clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.ReferPOS
                                                  ? true
                                                  : false
                                              }
                                              displayName={a?.displayName}
                                              answerId={a?.id}
                                            />
                                          </div>
                                        );
                                      })}
                                    </>
                                  )}
                                </div>
                              </div>
                              {q['Question Code'] == 'MassageOwnershipType' && dirtyMassageOwnerShip ? (
                                <div className='form-group d-flex'>
                                  <div className='cp-form__icon-left'></div>
                                  <div className='cp-form__group'>
                                    <Select
                                      options={layoutData['Massage Ownership Types'].map((item) => {
                                        return {
                                          value: item.fields.Value.value,
                                          label: item.fields.Value.value
                                        };
                                      })}
                                      className='cp-select cp-select__customize'
                                      classNamePrefix='cp-select'
                                      value={selectedOwnerTypeValuesList}
                                      onChange={onSelectMassageOwnership}
                                      isSearchable={true}
                                      isMulti
                                      isDisabled={massageOwnerShip ? false : true}
                                    />
                                    <div className='cp-select__customize-selected__item'>
                                      {selectedOwnerTypeValuesList.length > 0 &&
                                        selectedOwnerTypeValuesList.map((val, index) => (
                                          <div key={index} className='item'>
                                            <p className='item-text'>{val.label}</p>
                                            <div className='item-remove__icon icon-close' onClick={() => onRemoveMassageOwnerTypeBtnClick(val)}></div>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <Fragment key={iq}></Fragment>
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
                <div className='cp-form__button'>
                  <button className='cp-form__button__item btn btn-outline-primary' onClick={() => dispatch(setIsActiveQACustomerLifestyle(false))}>
                    <Text field={clientTellingPortalQuickAccess.quickAccessLayoutService?.['Cancel Button Label']} />
                  </button>
                  <button className='cp-form__button__item btn btn-os-orange btn-os-orange--text-primary' onClick={() => onSaveCustomerInformation()}>
                    <Text field={clientTellingPortalQuickAccess.quickAccessLayoutService?.['Save Button Label']} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </QuickAccessPopup>
  );
};

CustomeInfoPopup.propTypes = {
  closeAccessPopup: PropTypes.func,
  layoutData: PropTypes.object,
  dataModel: object
};

export default CustomeInfoPopup;
