import { ACTIVE_STATUS_KEY, COMPLETED_STATUS_KEY, DRAFT_STATUS_KEY, EXPIRED_STATUS_KEY, VIEWED_STATUS_KEY } from './configs';
import { SET_SALE_ORDER_STATUSES_TEXT } from './types';

export const setSaleOrderStatusesText = ({ draft, viewed, active, completed, expired }) => ({
  type: SET_SALE_ORDER_STATUSES_TEXT,
  payload: {
    [DRAFT_STATUS_KEY]: draft,
    [ACTIVE_STATUS_KEY]: active,
    [VIEWED_STATUS_KEY]: viewed,
    [COMPLETED_STATUS_KEY]: completed,
    [EXPIRED_STATUS_KEY]: expired
  }
});
