import produce from 'immer';

import {
  ACTIVE_STATUS_KEY,
  COMPLETED_STATUS_KEY,
  DRAFT_STATUS_KEY,
  EXPIRED_STATUS_KEY,
  VIEWED_STATUS_KEY
} from '@redux/actions/cpSaleOrderStatuses/configs';
import { SET_SALE_ORDER_STATUSES_TEXT } from '@redux/actions/cpSaleOrderStatuses/types';

const initialStates = {
  soCartStatuses: {
    [DRAFT_STATUS_KEY]: '',
    [ACTIVE_STATUS_KEY]: '',
    [VIEWED_STATUS_KEY]: '',
    [COMPLETED_STATUS_KEY]: '',
    [EXPIRED_STATUS_KEY]: ''
  }
};

const cpSaleOrderStatusesReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case SET_SALE_ORDER_STATUSES_TEXT:
      for (const [key, value] of Object.entries(action.payload)) {
        draft.soCartStatuses[key] = value;
      }
      break;
    }

    return draft;
  });

export default cpSaleOrderStatusesReducer;
