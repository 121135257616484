import './SaleOrdersListingNoResult.scss';

import React from 'react';
import PropTypes from 'prop-types';

const SaleOrdersListingNoResult = ({onLayoutData}) => {
  return (
    <div className='sale-orders-no-result'>
      <p className='sale-orders-no-result__descrition'>0 Result Found</p>
      <div className='search-no-result'>
        <div className='search-no-result__content'>
          <div className='search-no-result__content__image'>
            <img src={require('@assets/images/CP-images/icon-search-big.png')} alt='icon search' />
          </div>
          <h5 className='search-no-result__content__label text-center'>No Result Found</h5>
          <p className='search-no-result__content__desc text-center'>We can’t find any record matching your search.</p>
        </div>
      </div>
    </div>
  );
};

SaleOrdersListingNoResult.propTypes = {
  onLayoutData: PropTypes.any
};

export default SaleOrdersListingNoResult;