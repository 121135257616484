import './OrdersListing.scss';

import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@components/Share/Loading';
import {
  ACTIVE_STATUS_KEY, COMPLETED_STATUS_KEY,
  DRAFT_STATUS_KEY, EXPIRED_STATUS_KEY,
  VIEWED_STATUS_KEY
} from '@redux/actions/cpSaleOrderStatuses/configs';
import { getSOCarts } from '@redux/actions/getSOCarts';
import { getStoreLocator } from '@redux/actions/storeLocator';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useFormatDate } from '@utils/customsHook/useFormatDate';

import { SEARCH_PARAMS_CART_NAME_KEY } from '../../config';
import SaleOrdersListingNoResult from '../SaleOrdersListingNoResult';

const TAB_EVENT = {
  OPEN_ORDERS: 'open-orders',
  ACTIVE_ORDERS: 'active-orders',
  PAST_ORDER: 'past-orders'
};

const OrdersListing = ({
  onLayoutData,
  onIsSearch,
  onIsFilter,
  onIsReset,
  onDataFilter,
  onKeywordSearch,
  onOrdersListing,
  onItemSkip,
  setItemSkip,
  setResetPaging,
  currentStoreId
}) => {
  const dispatch = useDispatch();
  const storeLocatorList = useSelector((state) => state?.storeLocatorReducer)?.allStoreReducer?.storeLocatorList;
  const [tabEvent, setTabEvent] = useState(TAB_EVENT.ACTIVE_ORDERS);
  const [tabClick, setTabClick] = useState('active-orders');
  const isGettingData = useSelector((state) => state?.getSOCartsReducer?.isLoading);
  const isCPManager = SitecoreContextFactoryService.getValueContextItem('isCPManager');
  const salesStaffData = useSelector((state) => state?.cpLeadManagementReducer?.SaleStaffs);
  const formatDateUtil = useFormatDate();
  const { soCartStatuses } = useSelector((state) => state.cpSaleOrderStatusesReducer);

  const statusItem = (value) => {
    if (Array.isArray(onLayoutData?.Statuses) && typeof value === 'number') {
      return onLayoutData?.Statuses?.[value]?.toLowerCase()?.replace(/\s/g, '');
    }
  };

  const handleClickNavItem = (evt) => {
    setTabClick(evt.target.getAttribute('data-rb-event-key'));

    setItemSkip(0);

    setResetPaging(evt.target);
  };

  const handleChangeTab = useCallback((tabKey) => setTabEvent(tabKey), []);

  const handleFetchSaleOrdersListing = useCallback(
    (defaultStatusValue) => {
      let valueCategories = '';
      let valueExpiredDate = '';
      let valueStaffIds = '';
      if (onIsFilter && !onIsReset) {
        valueCategories = onDataFilter.category
          .filter((item) => item.id !== '')
          .map((item) => item.id)
          .join(',');

        if (onDataFilter?.expiryDate?.value === 'Customize') {
          valueExpiredDate = onDataFilter.dateUTC;
        } else {
          valueExpiredDate = onDataFilter.date;
        }

        valueStaffIds = onDataFilter.saleStaff.id;
      } else {
        valueCategories = '';

        valueExpiredDate = '';

        valueStaffIds = '';
      }

      if (!isCPManager && typeof window !== 'undefined') {
        valueStaffIds = JSON.parse(window.localStorage.getItem('cPUserData'))?.UserId || salesStaffData?.[0]?.StaffId;
      }

      dispatch(
        getSOCarts({
          expireddatefrom: onIsFilter && !onIsReset ? valueExpiredDate[0] : '',
          expireddateto: onIsFilter && !onIsReset ? valueExpiredDate[1] : '',
          totalfrom: onIsFilter && !onIsReset ? onDataFilter.salesOrderValue[0] : '',
          totalto: onIsFilter && !onIsReset ? onDataFilter.salesOrderValue[1] : '',
          searchkeyword: onIsSearch ? onKeywordSearch : '',
          staffIds: valueStaffIds,
          storeIds: onIsFilter && !onIsReset ? onDataFilter.store?.value : currentStoreId?.outletCode,
          statuses: onIsFilter && !onIsReset ? onDataFilter.status.value || defaultStatusValue : defaultStatusValue,
          categoryIds: valueCategories,
          sortby: 'expireddate',
          sortdirection: 'ASC',
          take: 10,
          skip: onItemSkip
        })
      );
    },
    [onIsFilter, onIsReset, onDataFilter, salesStaffData, currentStoreId, onItemSkip, onKeywordSearch]
  );

  const handleFetchDataByTabEvent = useCallback((tabEvent) => {
    const { OPEN_ORDERS, ACTIVE_ORDERS, PAST_ORDER } = TAB_EVENT;
    if (tabEvent === OPEN_ORDERS) {
      handleFetchSaleOrdersListing('Draft');
    } else if (tabEvent === ACTIVE_ORDERS) {
      handleFetchSaleOrdersListing('Active, Viewed');
    } else if (tabEvent === PAST_ORDER) {
      handleFetchSaleOrdersListing('Completed, Expired');
    }
  }, [handleFetchSaleOrdersListing]);

  useEffect(() => {
    dispatch(getStoreLocator());
  }, []);

  useEffect(() => {
    handleFetchDataByTabEvent(tabEvent);
  }, [tabEvent, onDataFilter, onItemSkip, onKeywordSearch]);

  useEffect(() => {
    // INFO: focus active tab when filter sale orders listing by status
    let activeTabKey = tabEvent;
    const { OPEN_ORDERS, ACTIVE_ORDERS, PAST_ORDER } = TAB_EVENT;
    if (onDataFilter && typeof onDataFilter !== 'string') {
      const filteringStatusValue = onDataFilter.status?.value || '';
      if (filteringStatusValue) {
        const filteringStatusIndex = Object.values(soCartStatuses).findIndex((item) => item === filteringStatusValue);
        if (filteringStatusIndex === DRAFT_STATUS_KEY) activeTabKey = OPEN_ORDERS;
        if (filteringStatusIndex === ACTIVE_STATUS_KEY || filteringStatusIndex === VIEWED_STATUS_KEY) activeTabKey = ACTIVE_ORDERS;
        if (filteringStatusIndex === EXPIRED_STATUS_KEY || filteringStatusIndex === COMPLETED_STATUS_KEY) activeTabKey = PAST_ORDER;
      } else {
        activeTabKey = ACTIVE_ORDERS;
      }
    }

    setTabEvent(activeTabKey);
  }, [onDataFilter, soCartStatuses, onItemSkip]);

  const openOrdersListing = useMemo(
    () => (onOrdersListing?.length ? onOrdersListing.filter((orderItem) => orderItem?.Status === DRAFT_STATUS_KEY) : []),
    [onOrdersListing]
  );

  const activeOrdersListing = useMemo(
    () =>
      onOrdersListing?.length
        ? onOrdersListing.filter((orderItem) => orderItem?.Status === ACTIVE_STATUS_KEY || orderItem?.Status === VIEWED_STATUS_KEY)
        : [],
    [onOrdersListing]
  );

  const pastOrdersListing = useMemo(
    () =>
      onOrdersListing?.length
        ? onOrdersListing.filter((orderItem) => orderItem?.Status === EXPIRED_STATUS_KEY || orderItem?.Status === COMPLETED_STATUS_KEY)
        : [],
    [onOrdersListing]
  );

  return (
    onLayoutData && (
      <div className='sale-orders-listing'>
        <div className='container tablet-container'>
          <div className='sale-orders-listing__tabs'>
            <Tabs activeKey={tabEvent} onSelect={handleChangeTab} onClick={handleClickNavItem}>
              <Tab key='openOrders' eventKey={TAB_EVENT.OPEN_ORDERS} title={onLayoutData?.['Open orders']?.value}>
                <div className='sale-orders-listing__tabs__content'>
                  {!isGettingData ? (
                    openOrdersListing?.length > 0 ? (
                      openOrdersListing.map((item, index) => (
                        <div key={index} className='sale-orders-item'>
                          {item?.SalesOrderId && (
                            <div className='sale-orders-item__box'>
                              <Text field={onLayoutData?.Order} tag='span' className='sale-orders-item__info sale-orders-item__info--order-id' />
                              <span className='sale-orders-item__info sale-orders-item__info--order-id'>{` ${item?.SalesOrderId}`}</span>
                            </div>
                          )}
                          {item?.CustomerName && (
                            <div className='sale-orders-item__box'>
                              <span className='sale-orders-item__info sale-orders-item__info--title'>{item?.CustomerName}</span>
                            </div>
                          )}
                          {item?.CreatedDateWithFormat && (
                            <div className='sale-orders-item__box'>
                              <Text field={onLayoutData?.['Order placed']} tag='span' className='sale-orders-item__info' />
                              <span className='sale-orders-item__info'>{`: ${formatDateUtil(item?.CreatedDateWithFormat)}`}</span>
                            </div>
                          )}
                          {item?.ExpiredDateWithFormat && (
                            <div className='sale-orders-item__box'>
                              <Text field={onLayoutData?.['Order to expire']} tag='span' className='sale-orders-item__info' />
                              <span className='sale-orders-item__info'>{`: ${formatDateUtil(item?.ExpiredDateWithFormat)}`}</span>
                            </div>
                          )}
                          {item?.StoreName && (
                            <div className='sale-orders-item__box'>
                              <Text field={onLayoutData?.['Store name']} tag='span' className='sale-orders-item__info' />
                              <span className='sale-orders-item__info'>{`: ${item.StoreName}`}</span>
                            </div>
                          )}
                          {item?.ProductNames && (
                            <div className='sale-orders-item__box'>
                              <span className='sale-orders-item__info sale-orders-item__info--title'>{`${item?.ProductNames[0]} ${
                                item?.ProductNames.length == 1
                                  ? ' '
                                  : onLayoutData?.['Package count']?.value.replace('{otherCount}', item?.ProductNames.length - 1)
                              } `}</span>
                            </div>
                          )}
                          {item?.Total && (
                            <div className='sale-orders-item__box'>
                              <Text field={onLayoutData?.Total} tag='span' className='sale-orders-item__info' />
                              <span className='sale-orders-item__info'>{`: ${item?.Total}`}</span>
                            </div>
                          )}
                          {/* {item?.Status && ( */}
                          <div className={`sale-orders-item__status sale-orders-item__status--${statusItem(item?.Status)}`}>
                            <Text field={{ value: statusItem(item?.Status) }} tag='span' className='sale-orders-item__info' />
                          </div>
                          {/* )} */}
                          <Link
                            field={{
                              href: `${onLayoutData?.['View details page']?.value?.href}?${SEARCH_PARAMS_CART_NAME_KEY}=${item?.CartName}`
                            }}
                            className='sale-orders-item__link'
                          >
                            <Text field={onLayoutData?.['View details']} />
                          </Link>
                        </div>
                      ))
                    ) : (
                      // <div className='sale-orders-item'>
                      //   <Text field={onLayoutData?.['No sale order']} />
                      // </div>
                      <SaleOrdersListingNoResult onLayoutData={onLayoutData} />
                    )
                  ) : (
                    <Loading />
                  )}
                </div>
              </Tab>
              <Tab key='activeOrders' eventKey={TAB_EVENT.ACTIVE_ORDERS} title={onLayoutData?.['Active orders']?.value}>
                <div className='sale-orders-listing__tabs__content'>
                  {!isGettingData ? (
                    activeOrdersListing?.length > 0 ? (
                      activeOrdersListing.map((item, index) => (
                        <div key={index} className='sale-orders-item'>
                          {item?.SalesOrderId && (
                            <div className='sale-orders-item__box'>
                              <Text field={onLayoutData?.Order} tag='span' className='sale-orders-item__info sale-orders-item__info--order-id' />
                              <span className='sale-orders-item__info sale-orders-item__info--order-id'>{` ${item?.SalesOrderId}`}</span>
                            </div>
                          )}
                          {item?.CustomerName && (
                            <div className='sale-orders-item__box'>
                              <span className='sale-orders-item__info sale-orders-item__info--title'>{item?.CustomerName}</span>
                            </div>
                          )}
                          {item?.CreatedDateWithFormat && (
                            <div className='sale-orders-item__box'>
                              <Text field={onLayoutData?.['Order placed']} tag='span' className='sale-orders-item__info' />
                              <span className='sale-orders-item__info'>{`: ${formatDateUtil(item?.CreatedDateWithFormat)}`}</span>
                            </div>
                          )}
                          {item?.ExpiredDateWithFormat && (
                            <div className='sale-orders-item__box'>
                              <Text field={onLayoutData?.['Order to expire']} tag='span' className='sale-orders-item__info' />
                              <span className='sale-orders-item__info'>{`: ${formatDateUtil(item?.ExpiredDateWithFormat)}`}</span>
                            </div>
                          )}
                          {item?.StoreName && (
                            <div className='sale-orders-item__box'>
                              <Text field={onLayoutData?.['Store name']} tag='span' className='sale-orders-item__info' />
                              <span className='sale-orders-item__info'>{`: ${item.StoreName}`}</span>
                            </div>
                          )}
                          {item?.ProductNames && (
                            <div className='sale-orders-item__box'>
                              <span className='sale-orders-item__info sale-orders-item__info--title'>{`${item?.ProductNames[0]} ${
                                item?.ProductNames.length == 1
                                  ? ' '
                                  : onLayoutData?.['Package count']?.value.replace('{otherCount}', item?.ProductNames.length - 1)
                              } `}</span>
                            </div>
                          )}
                          {item?.Total && (
                            <div className='sale-orders-item__box'>
                              <Text field={onLayoutData?.Total} tag='span' className='sale-orders-item__info' />
                              <span className='sale-orders-item__info'>{`: ${item?.Total}`}</span>
                            </div>
                          )}
                          {/* {item?.Status && ( */}
                          <div className={`sale-orders-item__status sale-orders-item__status--${statusItem(item?.Status)}`}>
                            <Text field={{ value: statusItem(item?.Status) }} tag='span' className='sale-orders-item__info' />
                          </div>
                          {/* )} */}
                          <Link
                            field={{ href: `${onLayoutData?.['View details page']?.value?.href}?${SEARCH_PARAMS_CART_NAME_KEY}=${item?.CartName}` }}
                            className='sale-orders-item__link'
                          >
                            <Text field={onLayoutData?.['View details']} />
                          </Link>
                        </div>
                      ))
                    ) : (
                      // <div className='sale-orders-item'>
                      // <Text field={onLayoutData?.['No sale order']} />
                      // </div>
                      <SaleOrdersListingNoResult onLayoutData={onLayoutData} />
                    )
                  ) : (
                    <Loading />
                  )}
                </div>
              </Tab>
              <Tab key='pastOrders' eventKey={TAB_EVENT.PAST_ORDER} title={onLayoutData?.['Past orders']?.value}>
                <div className='sale-orders-listing__tabs__content'>
                  {!isGettingData ? (
                    pastOrdersListing?.length > 0 ? (
                      pastOrdersListing.map((item, index) => (
                        <div key={index} className='sale-orders-item'>
                          {item?.SalesOrderId && (
                            <div className='sale-orders-item__box'>
                              <Text field={onLayoutData?.Order} tag='span' className='sale-orders-item__info sale-orders-item__info--order-id' />
                              <span className='sale-orders-item__info sale-orders-item__info--order-id'>{` ${item?.SalesOrderId}`}</span>
                            </div>
                          )}
                          {item?.CustomerName && (
                            <div className='sale-orders-item__box'>
                              <span className='sale-orders-item__info sale-orders-item__info--title'>{item?.CustomerName}</span>
                            </div>
                          )}
                          {item?.CreatedDateWithFormat && (
                            <div className='sale-orders-item__box'>
                              <Text field={onLayoutData?.['Order placed']} tag='span' className='sale-orders-item__info' />
                              <span className='sale-orders-item__info'>{`: ${formatDateUtil(item?.CreatedDateWithFormat)}`}</span>
                            </div>
                          )}
                          {item?.ExpiredDateWithFormat && (
                            <div className='sale-orders-item__box'>
                              <Text field={onLayoutData?.['Order to expire']} tag='span' className='sale-orders-item__info' />
                              <span className='sale-orders-item__info'>{`: ${formatDateUtil(item?.ExpiredDateWithFormat)}`}</span>
                            </div>
                          )}
                          {item?.StoreName && (
                            <div className='sale-orders-item__box'>
                              <Text field={onLayoutData?.['Store name']} tag='span' className='sale-orders-item__info' />
                              <span className='sale-orders-item__info'>{`: ${item.StoreName}`}</span>
                            </div>
                          )}
                          {item?.ProductNames && (
                            <div className='sale-orders-item__box'>
                              <span className='sale-orders-item__info sale-orders-item__info--title'>{`${item?.ProductNames[0]} ${
                                item?.ProductNames.length == 1
                                  ? ' '
                                  : onLayoutData?.['Package count']?.value.replace('{otherCount}', item?.ProductNames.length - 1)
                              } `}</span>
                            </div>
                          )}
                          {item?.Total && (
                            <div className='sale-orders-item__box'>
                              <Text field={onLayoutData?.Total} tag='span' className='sale-orders-item__info' />
                              <span className='sale-orders-item__info'>{`: ${item?.Total}`}</span>
                            </div>
                          )}
                          {/* {item?.Status && ( */}
                          <div className={`sale-orders-item__status sale-orders-item__status--${statusItem(item?.Status)}`}>
                            <Text field={{ value: statusItem(item?.Status) }} tag='span' className='sale-orders-item__info' />
                          </div>
                          {/* )} */}
                          <Link
                            field={{ href: `${onLayoutData?.['View details page']?.value?.href}?${SEARCH_PARAMS_CART_NAME_KEY}=${item?.CartName}` }}
                            className='sale-orders-item__link'
                          >
                            <Text field={onLayoutData?.['View details']} />
                          </Link>
                        </div>
                      ))
                    ) : (
                      // <div className='sale-orders-item'>
                      //   <Text field={onLayoutData?.['No sale order']} />
                      // </div>
                      <SaleOrdersListingNoResult onLayoutData={onLayoutData} />
                    )
                  ) : (
                    <Loading />
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    )
  );
};

OrdersListing.propTypes = {
  onLayoutData: PropTypes.any,
  onIsSearch: PropTypes.bool,
  onIsFilter: PropTypes.bool,
  onIsReset: PropTypes.bool,
  onKeywordSearch: PropTypes.any,
  onDataFilter: PropTypes.any,
  onOrdersListing: PropTypes.any,
  onItemSkip: PropTypes.number,
  setItemSkip: PropTypes.func,
  setResetPaging: PropTypes.func,
  currentStoreId: PropTypes.any
};

export default OrdersListing;
